import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

import { KnBrProductQuickEntryPopupModule } from './kn-br-product-quick-entry-popup/kn-br-product-quick-entry-popup.module';
import { KnBrProductQuickEntryComponent } from './kn-br-product-quick-entry.component';

@NgModule({
  declarations: [KnBrProductQuickEntryComponent],
  imports: [
    CommonModule,
    I18nModule,
    KnBrProductQuickEntryPopupModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrProductQuickEntryComponent: {
          component: KnBrProductQuickEntryComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrProductQuickEntryModule {}
