export const loginForm = {
  forgotPassword: 'Forgot password?',
  signIn: 'Sign In',
  register: 'Register',
  signUp: 'Sign Up',

  wrongEmailFormat: 'This is not a valid email format.',
  guestCheckout: 'Guest Checkout',
  dontHaveAccount: 'Don’t have an account?',
  emailAddress: {
    label: 'Email address',
    placeholder: 'Enter email',
  },
  password: {
    label: 'Password',
    placeholder: 'Password',
  },

  miniLogin: {
    userGreeting: 'Hi, {{name}}',
    signInRegister: 'Sign In / Register',
  },

  authMessages: {
    signedOutSuccessfully: 'Logged out Successfully.',
  },
  agree: 'Accept',
  policyacceptmsg: 'Thank you for Accepting the Privacy Policy.',
  termsofusemsg: 'Thank you for Accepting the Terms of Use.',
  commonmsg: 'Thank you for Accepting the Terms of use and Privacy Policy.'
};

export const register = {
  confirmPassword: {
    action: 'Confirm Password',
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
  },
  managementInMyAccount: 'Management in My Account.',
  termsAndConditions: 'Terms & Conditions',
  signIn: 'I already have an account. Sign In',
  register: 'Register',
  confirmNewPassword: 'Confirm New Password',
  resetPassword: 'Reset Password',
  createAccount: 'Create an account',
  title: 'Title',
  firstName: {
    label: 'First Name',
    placeholder: 'First Name',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Last Name',
  },
  emailAddress: {
    label: 'Email Address',
    placeholder: 'Email Address',
  },
  confEmailAddress: {
    label: 'Confirm Email Address',
    placeholder: 'Confirm Email Address',
  },
  accountNumber: {
    label: 'Account Number',
    placeholder: 'Account Number',
  },
  jobFunction: {
    label: 'Job Function',
    placeholder: 'Job Function',
  },
  phoneNumber: {
    label: 'Phone Number',
    placeholder: 'Phone Number',
  },
  password: {
    label: 'Password',
    placeholder: 'Password',
  },
  newPassword: 'New Password',
  emailMarketing:
    'Use my personal data to receive e-mail newsletters for marketing campaigns. To change your settings, go to Consent Management in My Account.',
  confirmThatRead: 'I am confirming that I have read and agreed with the',
  selectTitle: 'Select Title',
  passwordMinRequirements:
    'Password must be six characters minimum, with one uppercase letter, one number, one symbol',
  bothPasswordMustMatch: 'Both password must match',
  titleRequired: 'Title is required.',
  postRegisterMessage: 'Please log in with provided credentials.',
  pageHeader1: 'New Customer Registration',
  pageHeader2: 'Submit your registration below',
};
export const authMessages = {
  signedOutSuccessfully: 'Logged out Successfully.',
};
export const userAccount = {
  loginForm,
  register,
  authMessages
}
