import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrNacConfigurableComponent } from './kn-br-nac-configurable.component';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CmsPageGuard, FormErrorsModule } from '@spartacus/storefront';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from '@iplab/ngx-file-upload';

@NgModule({
  declarations: [KnBrNacConfigurableComponent],
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    FileUploadModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrNACConfigurableComponent: {
          component: KnBrNacConfigurableComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrNacConfigurableModule {}
