import { Action } from '@ngrx/store';

export enum KnBrOrderReturnActionTypes {
  KnBrReturnOrdersSearchAction = '[ReturnOrders] Search',
  KnBrReturnOrdersSearchSuccessAction = '[ReturnOrders] Search Success',
  KnBrReturnOrdersQuickSearchSuccessAction = '[ReturnOrders] Quick Search Success',
  KnBrReturnOrdersSearchFailureAction = '[ReturnOrders] Search Failure',
  KnBrReturnOrdersSearchResetAction = '[ReturnOrders] Search Reset',
}

/**
 * Action to Search Order Return
 */
export class KnBrReturnOrdersSearch implements Action {
  readonly type = KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Order Return Search
 */
export class KnBrReturnOrdersSearchSuccess implements Action {
  readonly type = KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Order Return Search
 */
export class KnBrReturnOrdersQuickSearchSuccess implements Action {
  readonly type = KnBrOrderReturnActionTypes.KnBrReturnOrdersQuickSearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure Order Return Search
 */
export class KnBrReturnOrdersSearchFailure implements Action {
  readonly type = KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on successful Order Return Search
 */
export class KnBrReturnOrdersSearchReset implements Action {
  readonly type = KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchResetAction;
  constructor(public payload: any) {}
}

export type KnBrOrderReturnActions =
  | KnBrReturnOrdersSearch
  | KnBrReturnOrdersSearchSuccess
  | KnBrReturnOrdersQuickSearchSuccess
  | KnBrReturnOrdersSearchFailure
  | KnBrReturnOrdersSearchReset;
