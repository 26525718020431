import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';

import { KnBrCustomerSearchResultComponent } from './kn-br-customer-search-result.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  declarations: [KnBrCustomerSearchResultComponent],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrPurchasingAgentSearchResultComponent: {
          component: KnBrCustomerSearchResultComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrCustomerSearchResultComponent],
})
export class KnBrCustomerSearchResultModule {}
