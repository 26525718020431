import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrOrderImportComponent } from './kn-br-order-import.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, AuthGuard, ConfigModule, RoutingConfig, CmsConfig } from '@spartacus/core';
import { FormErrorsModule, CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [KnBrOrderImportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    FileUploadModule,
    BrowserAnimationsModule,
    RouterModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrOrderImportComponent: {
          component: KnBrOrderImportComponent,
        },
      },
    } as CmsConfig),
    RouterModule.forChild([
      {
        data: { pageLabel: '/order-import', cxRoute: 'orderImport' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard, KnBrCustomerContextGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          orderImport: {
            paths: ['order-import'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrOrderImportModule {}
