import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';

import { KnBrProductQuickEntryPopupComponent } from './kn-br-product-quick-entry-popup.component';

@NgModule({
  declarations: [KnBrProductQuickEntryPopupComponent],
  imports: [CommonModule, IconModule, I18nModule, FormsModule, ReactiveFormsModule],
})
export class KnBrProductQuickEntryPopupModule {}
