import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  OccEndpointsService,
  PRODUCT_REVIEW_NORMALIZER,
  PRODUCT_REVIEW_SERIALIZER,
  ProductReviewsAdapter,
  Review,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';

@Injectable()
export class KnBrOccProductReviewsAdapter implements ProductReviewsAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    private customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
  }

  load(productCode: string, maxCount?: number): Observable<Review[]> {
    return this.http

      .get(this.getEndpoint(productCode, maxCount))
      .pipe(pluck('reviews'), this.converter.pipeableMany(PRODUCT_REVIEW_NORMALIZER));
  }

  post(productCode: string, review: any): Observable<Review> {
    review = this.converter.convert(review, PRODUCT_REVIEW_SERIALIZER);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new URLSearchParams();
    body.append('headline', review.headline);
    body.append('comment', review.comment);
    body.append('rating', review.rating.toString());
    body.append('alias', review.alias);

    return this.http.post(this.getEndpoint(productCode), body.toString(), {
      headers,
    });
  }

  protected getEndpoint(code: string, maxCount?: number): string {
    return this.occEndpoints.buildUrl('productReviews', {
      urlParams: {
        productCode: code,
        customerId: this.customerId,
      },
      queryParams: { maxCount },
    });
  }
}
