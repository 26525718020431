export const KN_BR_ADDRESS_FEATURE = 'kn-br-address';

export interface KnBrAddressState {
  oneTimeAddress?: any;
  isloading: boolean;
  error: any;
}

export interface StateWithKnBrAddress {
  [KN_BR_ADDRESS_FEATURE]: KnBrAddressState;
}
