import { CustomerSearchActions, KnBrCustomerActionTypes } from './kn-br-customer.action';
import { KnBrCustomerState } from './kn-br-customer.state';

export const initialState: KnBrCustomerState = {
  customers: undefined,
  error: undefined,
  searchRequest: undefined,
  isLoading: false,
  searchDisabled: false,
};

export function customerReducer(state = initialState, action: CustomerSearchActions): KnBrCustomerState {
  switch (action.type) {
    case KnBrCustomerActionTypes.CustomerSearchAction:
      return { ...state, searchRequest: action.payload, isLoading: true, searchDisabled: true };
    case KnBrCustomerActionTypes.CustomerSearchSuccessAction:
      return { ...state, customers: action.payload, isLoading: false, searchDisabled: false };
    case KnBrCustomerActionTypes.CustomerSearchFailureAction:
      return { ...state, error: action.payload, isLoading: false, searchDisabled: false };
    case KnBrCustomerActionTypes.CustomerSearchResetAction:
      return { ...state, customers: action.payload, isLoading: false, searchDisabled: false };
    default:
      return state;
  }
}
