import { CxEvent } from '@spartacus/core';

/**
 * Will be thrown in case lazy loaded modules are loaded and instantiated.
 *
 * This event is thrown for cms driven lazy loaded feature modules amd it's
 * dependencies
 */
export class QuickorderSearchSuccess extends CxEvent {
  /**
   * Event's type
   */
  static readonly type = 'QuickorderSearchSuccess';
  event?: string;
  attribute?: string;
  ProductId?: string;
}
export class MarkettingCenterLinkDownlaodEvent extends CxEvent {
  static readonly type = 'markettingCenterLinkDownlaodEvent';
  event?: string = 'marketting-center-download';
  link?: string;
  section?: string;
}
