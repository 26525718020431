<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/elements-content -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ng-template cxOutletRef="cx-header">
  <ng-container *ngIf="isLoginPage$ | async; else standardHeader">
    <cx-global-message></cx-global-message>
  </ng-container>
  <ng-template #standardHeader>
    <header>
      <cx-page-layout section="header"></cx-page-layout>
    </header>
    <ng-container *ngIf="!(isUndefinedPage$ | async)">
      <div class="breadcrumb col-lg-12 shadow bg-white pl-3 pt-2 pb-2 pr-3 d-flex align-items-center"
        *ngIf="hideBreadCrumb$ | async; else shoeBreadCrumb">
        <div class="
            d-none d-lg-block d-sm-none
            col-lg-8
            float-left
            align-items-center
            justify-content-center
            brand
            menu
            p-0
          ">
          <cx-page-slot position="NavigationBar"></cx-page-slot>
        </div>
        <div class="float-left p-0 d-block d-sm-block d-lg-none res-brands-btn">
          <div class="col-lg-12 p-0 float-left d-flex align-items-center justify-content-center brand menu">
            <cx-page-slot position="NavigationBar"></cx-page-slot>
          </div>
        </div>
        <div class="col-lg-4 float-right p-0 res-cart-count">
          <cx-page-slot position="MiniCart" class="cart-count"></cx-page-slot>
        </div>
      </div>
    </ng-container>
    <cx-global-message></cx-global-message>
  </ng-template>
</ng-template>
<ng-template cxOutletRef="SiteLogo" cxOutletPos="before" let-model>
  <div class="top-bar-nav float-left" *ngIf="isLoggedIn$ | async">
    <a (click)="changeMenuState()" class="pt-4 pr-3 pl-0 font-xl-size color-white hover-link cursor-pointer"><i
        class="fas fa-bars"></i></a>
  </div>
</ng-template>
<ng-template cxOutletRef="SiteLogo" cxOutletPos="after">
  <ng-container *ngIf="isLoggedIn$ | async">
    <cx-page-slot position="SearchBox"></cx-page-slot>
  </ng-container>
</ng-template>
<ng-template cxOutletRef="SiteContext" cxOutletPos="before">
  <ng-container *ngIf="customerContext$ | async as customer; else noCustomerContext">
    <ng-container *ngIf="redirectionDetail$ | async as redirectionDetail; else noSwitcher">
      <div *ngIf="redirectionDetail.switcher; else noSwitcher" ngbDropdown
        class="logout-btn top-bar-nav float-right mr-2">
        <span id="dropdownBasic1" class="
            float-left
            pr-0
            pl-0
            font-size
            color-white
            hover-link
            cursor-pointer
            customer-context
            d-flex
            ali
            align-items-center
            justify-content-end
          " ngbDropdownToggle>
          <span class="cart-context d-flex align-items-center mr-1">
            <div class="res-cart-context float-left">
              <span><i class="fas fa-building mr-2 p-res-0" aria-hidden="true"></i>{{ customer.customerNumber |
                removeLeadingZeros }} {{ customer.customerName }}</span>
              <ng-container *ngIf="customer.telephone"><br />
                <span>
                  <i class="fas fa-phone-square mr-2 p-res-0" aria-hidden="true"></i>
                  {{ 'label.phoneNo' | cxTranslate }} {{ customer.telephone }}
                </span>
              </ng-container>
            </div>
          </span>
        </span>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu knbr-dropdown">
          <a class="font-size" [routerLink]="{ cxRoute: 'switchAccount' } | cxUrl" ngbDropdownItem>{{
            'label.change' | cxTranslate
            }}</a>
          <a class="font-size" (click)="removeCustomerContext()" ngbDropdownItem>{{ 'label.remove' | cxTranslate }}</a>
        </div>
      </div>
    </ng-container>
    <ng-template #noSwitcher>
      <div class="logout-btn top-bar-nav float-right mr-2">
        <span class="
            float-left
            pr-0
            pl-0
            font-size
            color-white
            hover-link
            customer-context
            d-flex
            ali
            align-items-center
            justify-content-end
          ">
          <span class="cart-context d-flex align-items-center mr-1">
            <div class="res-cart-context float-left">
              <span><i class="fas fa-building mr-2 p-res-0" aria-hidden="true"></i>{{ customer.customerNumber |
                removeLeadingZeros }} {{ customer.customerName }}</span>
              <ng-container *ngIf="customer.telephone"><br />
                <span><i class="fas fa-phone-square mr-2 p-res-0" aria-hidden="true"></i>{{ 'label.phoneNo' |
                  cxTranslate }} {{ customer.telephone }}</span>
              </ng-container>
            </div>
          </span>
        </span>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #noCustomerContext>
    <div class="logout-btn top-bar-nav float-right mr-2 cursor-pointer" *ngIf="isLoggedIn$ | async">
      <a [routerLink]="{ cxRoute: 'switchAccount' } | cxUrl"
        class="pt-4 pr-0 pl-3 pb-4 font-size color-white hover-link">
        <i class="fas fa-building pr-2"></i>
      </a>
    </div>
  </ng-template>
  <ng-container *ngIf="user$ | async as user">
    <div class="logout-btn top-bar-nav float-right mr-2 cursor-pointer res-user d-none d-sm-none d-lg-block"
      *ngIf="isLoggedIn$ | async">
      <span href="" class="pt-4 pr-0 pl-3 pb-4 font-size color-white hover-link user-span custo">
        <i class="fas fa-user-circle pr-2" aria-hidden="true"></i>
        <span class="user-name"> {{ user.name }} </span>
      </span>
    </div>
  </ng-container>
  <div class="logout-btn top-bar-nav float-left cursor-pointer language">
    <span href="" class="pt-4 pr-0 pl-3 pb-4 font-lg-size color-white hover-link">
      <i class="fas fa-language pr-2" aria-hidden="true"></i>
    </span>
  </div>
</ng-template>
<ng-template cxOutletRef="SiteContext" cxOutletPos="after" *ngIf="isStaticPage$ | async">
  <ng-container *ngIf="!(isLoggedIn$ | async)">
    <a class="font-size color-white hover-link signup-link ml-3 mr-3" [routerLink]="{ cxRoute: 'register' } | cxUrl">{{
      'loginForm.signUp' | cxTranslate
      }}</a>
  </ng-container>
  <ng-container *ngIf="!(isLoggedIn$ | async)">
    <a class="font-size color-white hover-link signin-link" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
      'loginForm.signIn' | cxTranslate
      }}</a>
  </ng-container>
</ng-template>
<ng-template cxOutletRef="SiteContext" cxOutletPos="after">
  <ng-container *ngIf="isLoggedIn$ | async">
    <a class="font-size color-white hover-link signin-link logout-button" (click)="logout()">
      <i class="fas fa-power-off"></i>
    </a>
  </ng-container>
</ng-template>
<ng-template #shoeBreadCrumb>
  <div class="breadcrumb col-lg-12 shadow bg-white pl-3 pt-2 pb-2 pr-3 d-flex align-items-center">
    <div class="col-lg-3 float-left p-0 res-selected-pages">
      <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>
    </div>
    <div class="col-lg-5 float-left d-flex align-items-center justify-content-center brand menu">
      <cx-page-slot position="NavigationBar"></cx-page-slot>
    </div>
    <div class="col-lg-4 text-right p-0 res-cart-count">
      <cx-page-slot position="MiniCart" class="cart-count"></cx-page-slot>
    </div>
  </div>
</ng-template>
