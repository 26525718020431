import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrQuotePartialItemComponent } from './kn-br-quote-partial-item.component';
import { I18nModule } from '@spartacus/core';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  imports: [CommonModule, I18nModule, KnBrCustomPipesModule],
  declarations: [KnBrQuotePartialItemComponent],
  exports: [KnBrQuotePartialItemComponent],
})
export class KnBrQuotePartialItemModule {}
