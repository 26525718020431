import { Component, OnInit, Input } from '@angular/core';
import { PaginationModel } from '@spartacus/core';

@Component({
  selector: 'kn-br-browsing',
  templateUrl: './kn-br-browsing.component.html',
  styleUrls: ['./kn-br-browsing.component.scss']
})
export class KnBrBrowsingComponent implements OnInit {

  @Input() recordsShown: number;
  @Input() paginate: PaginationModel;
  @Input() recordType: string;
  constructor() { }

  ngOnInit(): void {
  }

}
