import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { KnBrOrder } from '../models/kn-br-order.model';

@Injectable()
export class KnBrOrderNormalizer implements Converter<Occ.Order, KnBrOrder> {
  convert(source: Occ.Order, target: KnBrOrder): KnBrOrder {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    return target;
  }
}
