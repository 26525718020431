import { OccConfig } from '@spartacus/core';

export const knBrUserRegisterEndpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        userRegister: 'knbr/createUser',
      },
    },
  },
};
