<div class="col-md-6 float-left customersearch-forms login-forms p-0 customer-home-forms">
  <form [formGroup]="orderForm" (ngSubmit)="submitForm()">
    <div class="col-lg-12 p-0 float-left">
      <div class="col-md-6 p-0 float-left">
        <label for="inputEmail4">{{ 'orderForms.label.keywordSearch' | cxTranslate }} * </label>
      </div>
      <div class="col-md-6 p-0 float-left">
        <div class="form-check mb-3 float-right">
          <input class="form-check-input" formControlName="onlyProductId" type="checkbox" value="" id="defaultCheck1" />
          <label class="form-check-label" for="defaultCheck1"
            >{{ 'orderForms.label.onlyProductIds' | cxTranslate }}
          </label>
        </div>
      </div>

      <div class="form-group col-md-12 p-0 float-left mb-2">
        <input
          type="email"
          class="form-control"
          formControlName="keywords"
          id="inputEmail4"
          placeholder="{{ 'orderForms.label.keywordSearch' | cxTranslate }}"
        />
      </div>
    </div>

    <div class="col-md-12 p-0 float-left">
      <div class="form-check col-md-6 float-left">
        <input
          class="form-check-input"
          formControlName="orderFormRadio"
          type="radio"
          name="orderFormRadio"
          id="orderForms"
          value="OrderForm"
          (change)="onChangeOrderForm()"
          checked
        />
        <label class="form-check-label" for="orderForms">{{ 'orderForms.label.orderForm' | cxTranslate }} </label>
      </div>
      <div class="form-check col-md-6 float-left">
        <input
          class="form-check-input"
          formControlName="orderFormRadio"
          type="radio"
          name="orderFormRadio"
          id="createOrderForm"
          value="CreateOrderForm"
          (change)="onChangeOrderForm()"
        />
        <label class="form-check-label" for="createOrderForm"
          >{{ 'orderForms.label.createOrderForm' | cxTranslate }}
        </label>
      </div>
    </div>

    <div class="col-lg-12 p-0 float-left mt-2">
      <button
        [disabled]="orderForm.invalid || (isSearchDisabled$ | async)"
        type="submit"
        class="btn btn-primary btn-block"
      >
        {{ 'button.search' | cxTranslate }}
      </button>
    </div>
  </form>
</div>
