import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { KnBrOrderFormSearchModule } from './kn-br-order-form-search/kn-br-order-form-search.module';
import { KnBrOrderFormSearchResultModule } from './kn-br-order-form-search-result/kn-br-order-form-search-result.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrOrderFormSearchModule,
    KnBrOrderFormSearchResultModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/order-forms', cxRoute: 'orderForms' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          orderForms: {
            paths: ['order-forms'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrOrderFormsModule {}
