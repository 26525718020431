import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'knbr-kn-br-upload-new-template-dialog',
  templateUrl: './kn-br-upload-new-template-dialog.component.html',
  styleUrls: ['./kn-br-upload-new-template-dialog.component.scss'],
})
export class KnBrUploadNewTemplateDialogComponent implements OnInit {
  public fileUploadControl = new FileUploadControl()
    .setListVisibility(false)
    .setValidators([FileUploadValidators.filesLimit(1), Validators.required]);
  documentTypes = '.csv';

  uploadTemplateForm: UntypedFormGroup = this.fb.group({
    templateName: ['', Validators.required],
  });

  constructor(private activeModal: NgbActiveModal, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}

  dismiss() {
    this.activeModal.dismiss();
  }

  upload() {
    if (this.uploadTemplateForm.valid && this.fileUploadControl.value.length > 0) {
      this.activeModal.close(this.uploadTemplateForm.value);
    } else {
      this.uploadTemplateForm.markAllAsTouched();
    }
  }
}
