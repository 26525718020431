<div
  class="
    bg-white
    customersearch-list-titles
    table-content
    border-top
    float-left
    w-100
    pt-2
    pb-2
    pl-3
    pr-3
    font-weight-normal
    d-flex
    align-items-center
  "
  *ngIf="item"
>
  <div class="col-md-1 text-center padding-res-0 float-left">
    <cx-media [container]="item.images" format="product"></cx-media>
  </div>
  <div class="col-md-3 pl-0 float-left title-wrap font-size color-font">
    <span class="res-titles"> {{ 'orderForms.label.item' | cxTranslate }} </span>
    <label [innerHtml]="item.name"></label>
    <label class="mb-0">{{ item.code }}</label>
  </div>
  <div class="col-md-2 text-center float-left title-wrap font-size color-font">
    <span class="res-titles"> {{ 'orderForms.label.listPrice' | cxTranslate }}</span>
    {{ item.price.formattedValue }}
  </div>
  <ng-container *ngIf="isOrderForm">
    <div class="col-md-2 float-left text-center title-wrap font-size color-font">
      <span class="res-titles">{{ 'orderForms.label.qty' | cxTranslate }} </span>
      <input
        type="text"
        [formControl]="quantity"
        class="form-control w-100 mb-0"
        (blur)="onchageQuantity($event)"
        knBrNumbersOnly
      />
    </div>
    <div class="col-md-2 float-left text-center title-wrap font-size color-font">
      <span class="res-titles"> {{ 'orderForms.label.minQty' | cxTranslate }}</span>
      {{ item.minOrderQuantity }}
    </div>

    <div class="col-md-2 float-left text-center title-wrap font-size color-font">
      <span class="res-titles"> {{ 'orderForms.label.total' | cxTranslate }} </span>
      {{ quantity.value * item.price.value | number: '1-2.2' }}
    </div>
  </ng-container>
  <ng-container *ngIf="!isOrderForm">
    <div class="col-md-5 float-left text-center title-wrap font-size color-font">
      <span class="res-titles">{{ 'orderForms.label.selectProduct' | cxTranslate }} </span>
      <div class="form-check mb-1">
        <input
          class="form-check-input"
          (change)="onSelectProduct($event)"
          [formControl]="selectProduct"
          type="checkbox"
          value=""
        />
        <label class="form-check-label">{{ item.code }} </label>
      </div>
    </div>
  </ng-container>
</div>
