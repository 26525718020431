import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrQuickInvoiceComponent } from './kn-br-quick-invoice.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ListNavigationModule, SpinnerModule } from '@spartacus/storefront';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    ReactiveFormsModule,
    NgbModule,
    ListNavigationModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBr360InvoiceHistoryComponent: {
          component: KnBrQuickInvoiceComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [KnBrQuickInvoiceComponent],
})
export class KnBrQuickInvoiceModule {}
