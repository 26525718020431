import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { KnBrQuickInvoiceModule } from './kn-br-quick-invoice/kn-br-quick-invoice.module';
import { KnBrQuickOrderHistroyModule } from './kn-br-quick-order-histroy/kn-br-quick-order-histroy.module';
import { KnBrQuickReturnOrderModule } from './kn-br-quick-return-order/kn-br-quick-return-order.module';

@NgModule({
  imports: [
    CommonModule,
    KnBrQuickInvoiceModule,
    KnBrQuickOrderHistroyModule,
    KnBrQuickReturnOrderModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/customer-360/', cxRoute: 'customer360' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          customer360: {
            paths: ['customer-360/'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrCustomer360HomeModule {}
