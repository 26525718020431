import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { KnBrCommonService } from 'src/services/kn-br-common.service';
import { UrlPatternService } from 'src/services/url-pattern.service';
@Injectable()
export class CartRequestInterceptor implements HttpInterceptor {
  private isLoggedOut: boolean = false;
  constructor(private knBrCommonService: KnBrCommonService, private urlPatternService: UrlPatternService) {
    this.knBrCommonService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.isLoggedOut = !isLoggedIn;
    });
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isLoggedOut && this.urlPatternService.isBlockedUrl(request.url)) {
      console.warn('Blocked request to:', request.url);
      return of();
    }
    if (this.isLoggedOut && this.urlPatternService.isBlockedStatusUrl(request.url)) {
      console.warn('Blocked status request to:', request.url);
      return of();
    }
    if (this.isLoggedOut && this.urlPatternService.isBlockedStatusUrlNull(request.url)) {
      console.warn('Blocked status request to:', request.url);
      return of();
    }
    return next.handle(request);
  }
}
