<form (ngSubmit)="onSubmit()" [formGroup]="quoteForm">
  <div class="bg-white rounded p-0 col-lg-12 float-left">
    <div class="col-md-12 float-left customersearch-forms mt-4 login-forms p-0">
      <div class="form-group mt-0 col-lg-12 pl-0 float-left mb-3 p-res-0 font-text-bold color-font"
        *ngIf="copiedQuoteData">
        {{ 'quoteCommon.label.referenceQuote' | cxTranslate }}: &nbsp; {{ copiedQuoteData.code }} -
        {{ copiedQuoteData.name }}
      </div>
      <div class="col-lg-12 p-0 float-left">
        <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
          <label class="text-muted m-0">
            {{ 'quoteCommon.label.validFrom' | cxTranslate }}
            <span class="text-danger font-size-18 float-right ml-2">*</span></label>
          <div class="col-lg-12 float-left p-0">
            <div class="form-group col-lg-12 pl-0 m-0 float-left p-0 valid-to p-res-0 position-relative">
              <div class="input-group">
                <input class="form-control w-100 cursor-pointer" [placeholder]="dateFormat$ | async"
                  [maxDate]="quoteForm.get('quoteValidTo').value" [minDate]="ngbCalendar.getToday()" name="validFrom"
                  ngbDatepicker #validFrom="ngbDatepicker" formControlName="quoteValidFrom" />
                <div class="input-group-append">
                  <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="validFrom.toggle()"
                    type="button">
                    <i class="far fa-calendar-alt color-font"></i>
                  </button>
                </div>
              </div>
              <cx-form-errors *ngIf="
                  quoteForm.get('quoteValidFrom')?.errors?.required ||
                  quoteForm.get('quoteValidFrom')?.errors?.ngbDate?.invalid
                " [control]="quoteForm.get('quoteValidFrom')"></cx-form-errors>
              <span class="kn-br-form-errors" *ngIf="
                  quoteForm.get('quoteValidFrom')?.errors?.ngbDate?.minDate ||
                  quoteForm.get('quoteValidFrom')?.errors?.ngbDate?.maxDate
                ">
                <p>{{ 'formErrors.invalidDateRange' | cxTranslate }}</p>
              </span>
            </div>
          </div>
        </div>

        <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
          <label class="text-muted m-0">{{ 'quoteCommon.label.validTo' | cxTranslate }}
            <span class="text-danger font-size-18 float-right ml-2">*</span></label>
          <div class="col-lg-12 float-left p-0">
            <div class="form-group col-lg-12 pl-0 m-0 float-left p-0 valid-to p-res-0 position-relative">
              <div class="input-group">
                <input class="form-control w-100 cursor-pointer" [placeholder]="dateFormat$ | async" name="quoteValidTo"
                  [minDate]="quoteForm.get('quoteValidFrom').value" ngbDatepicker #validTo="ngbDatepicker"
                  formControlName="quoteValidTo" />
                <div class="input-group-append">
                  <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="validTo.toggle()"
                    type="button">
                    <i class="far fa-calendar-alt color-font"></i>
                  </button>
                </div>
              </div>
              <cx-form-errors *ngIf="
                  quoteForm.get('quoteValidTo')?.errors?.required ||
                  quoteForm.get('quoteValidTo')?.errors?.ngbDate?.invalid
                " [control]="quoteForm.get('quoteValidTo')"></cx-form-errors>
              <span class="kn-br-form-errors" *ngIf="
                  quoteForm.get('quoteValidTo')?.errors?.ngbDate?.minDate ||
                  quoteForm.get('quoteValidTo')?.errors?.ngbDate?.maxDate
                ">
                <p>{{ 'formErrors.invalidDateRange' | cxTranslate }}</p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
        <label class="text-muted m-0">{{ 'quoteCommon.label.requestedDeliveryDate' | cxTranslate }}</label>
        <div class="col-lg-12 float-left p-0">
          <div class="form-group col-lg-12 pl-0 m-0 float-left p-0 valid-to p-res-0 position-relative">
            <div class="input-group">
              <input class="form-control w-100 cursor-pointer" [placeholder]="dateFormat$ | async" name="rdd"
                ngbDatepicker #rdd="ngbDatepicker" [minDate]="ngbCalendar.getToday()" formControlName="rdd" />
              <div class="input-group-append">
                <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="rdd.toggle()"
                  type="button">
                  <i class="far fa-calendar-alt color-font"></i>
                </button>
              </div>
            </div>
            <cx-form-errors [control]="quoteForm.get('rdd')"></cx-form-errors>
          </div>
        </div>
      </div>

      <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 p-res-0">
        <input type="text" class="form-control w-100" [placeholder]="'quoteCommon.label.customerPO' | cxTranslate"
          formControlName="purchaseOrderNumber" />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'quoteCommon.label.customerPO' | cxTranslate }}</label>
      </div>

      <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-4 p-res-0">
        <input type="text" class="form-control w-100" [placeholder]="'quoteCommon.label.jobNameNumber' | cxTranslate"
          formControlName="jobName" />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'quoteCommon.label.jobNameNumber' | cxTranslate }}
          <span class="text-danger font-size-18 float-right ml-2">*</span></label>
        <cx-form-errors [control]="quoteForm.get('jobName')"></cx-form-errors>
      </div>
    </div>

    <div class="remember-me col-md-12 float-left mb-3 d-flex justify-content-start pl-4">
      <div class="form-check custom-checkbox m-0 p-0">
        <input type="checkbox" class="custom-control-input" id="defaultUnchecked" formControlName="shipComplete" />
        <label class="custom-control-label color-font" for="defaultUnchecked">{{
          'quoteCommon.label.shipComplete' | cxTranslate
          }}</label>
      </div>
    </div>

    <div class="col-md-12 float-left customersearch-forms login-forms p-0">
      <div class="form-group head-notes mt-2 col-md-3 pl-0 float-left mb-3">
        <label for="exampleFormControlTextarea1">{{ 'quoteCommon.label.headerNotes' | cxTranslate }}</label>
        <textarea class="form-control w-100" id="exampleFormControlTextarea1" rows="2"
          [placeholder]="'quoteCommon.label.headerNotes' | cxTranslate" formControlName="headerNotes"></textarea>
      </div>
      <div class="col-md-9 float-left p-0 mt-4">
        <button type="submit" class="mt-1 mt-sm-0 btn btn-primary col-md-3 mr-3"
          [disabled]="(isCreateDisabled$ | async) || !quoteForm.valid">
          {{ 'button.create' | cxTranslate }}
        </button>
        <button type="button" class="mt-1 mt-sm-0 btn btn-outline-primary col-md-3" (click)="goToListPage()">
          {{ 'button.cancel' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</form>
