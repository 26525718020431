import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule, RoutingModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';

import { KnBrDraftOrderDetailHeaderComponent } from './kn-br-draft-order-detail-header.component';

@NgModule({
  declarations: [KnBrDraftOrderDetailHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RoutingModule,
    UrlModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderHeaderComponent: {
          component: KnBrDraftOrderDetailHeaderComponent,
        },
        KnBrDraftOrderHeaderComponentReadOnly: {
          component: KnBrDraftOrderDetailHeaderComponent,
        },
      },
    } as CmsConfig),
    NgSelectModule,
  ],
  exports: [KnBrDraftOrderDetailHeaderComponent],
})
export class KnBrDraftOrderDetailHeaderModule {}
