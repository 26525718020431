import { Component } from '@angular/core';
import { Title, User } from '@spartacus/core';
import { UpdateProfileComponent, UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { Observable } from 'rxjs';

@Component({
  selector: 'knbr-update-profile',
  templateUrl: './kn-br-update-profile.component.html',
})
export class KnBrUpdateProfileComponent extends UpdateProfileComponent {
  titles$: Observable<Title[]>;
  user$: Observable<User>;
  loading$: Observable<boolean>;

  constructor(protected service: UpdateProfileComponentService) {
    super(service);
  }
}
