import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { KnBrMiniCartComponent } from './kn-br-mini-cart.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        IconModule,
        I18nModule,
        NgbModule,
        ConfigModule.withConfig({
            cmsComponents: {
                MiniCartComponent: {
                    component: KnBrMiniCartComponent,
                },
            },
        } as CmsConfig),
    ],
    declarations: [KnBrMiniCartComponent],
    exports: [KnBrMiniCartComponent],
    bootstrap: [KnBrMiniCartComponent]
})
export class KnBrMiniCartModule {}
