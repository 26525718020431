import { Action } from '@ngrx/store';

export enum KnBrCartContextActionTypes {
  KnBrCartContextLoadAction = '[CartContext] Load ',
  KnBrCartContextLoadSuccessAction = '[CartContext] Load Success',
  KnBrCartContextAddUpdateAction = '[CartContext] ADD|UPDATE',
  KnBrCartContextRemoveAction = '[CartContext] Remove',
  KnBrCartContextResetAction = '[CartContext] Reset',
}

export class KnBrCartLoadContext implements Action {
  readonly type = KnBrCartContextActionTypes.KnBrCartContextLoadAction;
}
export class KnBrCartLoadSuccessContext implements Action {
  readonly type = KnBrCartContextActionTypes.KnBrCartContextLoadSuccessAction;
  constructor(public payload: string) {}
}

export class KnBrCartAddUpdateContext implements Action {
  readonly type = KnBrCartContextActionTypes.KnBrCartContextAddUpdateAction;
  constructor(public payload: string) {}
}
export class KnBrCartRemoveContext implements Action {
  readonly type = KnBrCartContextActionTypes.KnBrCartContextRemoveAction;
  constructor(public payload?: string) {}
}

export class KnBrCartResetContext implements Action {
  readonly type = KnBrCartContextActionTypes.KnBrCartContextResetAction;
  constructor(public payload?: string) {}
}
export type KnBrCartContextActions =
  | KnBrCartAddUpdateContext
  | KnBrCartRemoveContext
  | KnBrCartResetContext
  | KnBrCartLoadContext
  | KnBrCartLoadSuccessContext;
