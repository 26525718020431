import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { KnBrInvoiceAccountSummaryComponent } from './kn-br-invoice-account-summary.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  declarations: [KnBrInvoiceAccountSummaryComponent],
  imports: [
    CommonModule,
    I18nModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrInvoiceHeaderComponent: {
          component: KnBrInvoiceAccountSummaryComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrInvoiceAccountSummaryModule {}
