import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrReturnOrderDetailsItemsComponent } from './kn-br-return-order-details-items.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';

@NgModule({
  declarations: [KnBrReturnOrderDetailsItemsComponent],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturnRequestItemsComponent: {
          component: KnBrReturnOrderDetailsItemsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrReturnOrderDetailsItemsModule {}
