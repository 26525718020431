import { Action } from '@ngrx/store';

export enum KnBrQuoteEntryActionTypes {
  KnBrQuoteAddEntryAction = '[Quote-entry] Add Entry',
  KnBrQuoteAddEntrySuccessAction = '[Quote-entry] Add Entry Success',
  KnBrQuoteAddEntryFailureAction = '[Quote-entry] Add Entry Failure',
  KnBrQuoteRemoveEntryAction = '[Quote-entry] Remove Entry',
  KnBrQuoteRemoveEntrySuccessAction = '[Quote-entry] Remove Entry Success',
  KnBrQuoteRemoveEntryFailureAction = '[Quote-entry] Remove Entry Failure',
  KnBrQuoteUpdateEntryAction = '[Quote-entry] Update Entry',
  KnBrQuoteUpdateEntrySuccessAction = '[Quote-entry] Update Entry Success',
  KnBrQuoteUpdateEntryFailureAction = '[Quote-entry] Update Entry Failure',

  KnBrQuoteLoadEntryAction = '[Quote-entry] Load entry',
  KnBrQuoteLoadEntryFailureAction = '[Quote-entry] Load Quote Entry Fail',
  KnBrQuoteLoadEntrySuccessAction = '[Quote-entry] Load Quote Entry Success',
  KnBrQuoteAddEntriesAction = '[Quote-entry] Add Entries',
  KnBrQuoteAddEntriesSuccessAction = '[Quote-entry] Add Entries Success',
  KnBrQuoteAddEntriesFailureAction = '[Quote-entry] Add Entries Failure',
}

export class KnBrQuoteAddEntry implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteAddEntryAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteAddEntrySuccess implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteAddEntrySuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteAddEntryFailure implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteAddEntryFailureAction;
  constructor(public error: any) {}
}

export class KnBrQuoteRemoveEntry implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteRemoveEntryAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteRemoveEntrySuccess implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteRemoveEntrySuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteRemoveEntryFailure implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteRemoveEntryFailureAction;
  constructor(public error: Error) {}
}

export class KnBrQuoteUpdateEntry implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteUpdateEntryAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteUpdateEntrySuccess implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteUpdateEntrySuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteUpdateEntryFailure implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteUpdateEntryFailureAction;
  constructor(public error: Error) {}
}

export class KnBrQuoteLoadEntry implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteLoadEntryAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteLoadEntrySuccess implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteLoadEntrySuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteLoadEntryFailure implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteLoadEntryFailureAction;
  constructor(public error: Error) {}
}

export class KnBrQuoteAddEntries implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteAddEntriesSuccess implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesSuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteAddEntriesFailure implements Action {
  readonly type = KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesFailureAction;
  constructor(public payload: any) {}
}
export type KnBrQuoteEntryAction =
  | KnBrQuoteAddEntry
  | KnBrQuoteAddEntrySuccess
  | KnBrQuoteAddEntryFailure
  | KnBrQuoteRemoveEntry
  | KnBrQuoteRemoveEntrySuccess
  | KnBrQuoteRemoveEntryFailure
  | KnBrQuoteUpdateEntry
  | KnBrQuoteUpdateEntrySuccess
  | KnBrQuoteUpdateEntryFailure
  | KnBrQuoteLoadEntry
  | KnBrQuoteLoadEntrySuccess
  | KnBrQuoteLoadEntryFailure
  | KnBrQuoteAddEntries
  | KnBrQuoteAddEntriesSuccess
  | KnBrQuoteAddEntriesFailure;
