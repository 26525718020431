import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AuthConfigService,
  CmsConfig,
  ConfigModule,
  GlobalMessageService,
  I18nConfig,
  I18nModule,
  provideConfig,
  RoutingService,
  UrlModule,
} from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { userProfileTranslations, userProfileTranslationChunksConfig } from '@spartacus/user/profile/assets';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';

import { KnBrForgotPasswordFormComponent } from './kn-br-forgot-password.component';

@NgModule({
    declarations: [KnBrForgotPasswordFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        I18nModule,
        FormErrorsModule,
        SpinnerModule,
        ConfigModule.withConfig({
            cmsComponents: {
                KnBrForgotPasswordFormComponent: {
                    component: KnBrForgotPasswordFormComponent,
                    providers: [
                        {
                            provide: ForgotPasswordComponentService,
                            useClass: ForgotPasswordComponentService,
                            deps: [UserPasswordFacade, RoutingService, AuthConfigService, GlobalMessageService],
                        },
                    ],
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrForgotPasswordFormComponent],
    providers: [
        provideConfig({
            i18n: {
                resources: userProfileTranslations,
                chunks: userProfileTranslationChunksConfig,
            },
        } as I18nConfig),
    ]
})
export class KnBrForgotPasswordModule {}
