import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrOrderHistorySearchResultModule } from './kn-br-order-history-search-result/kn-br-order-history-search-result.module';
import { KnBrOrderHistorySearchModule } from './kn-br-order-history-search/kn-br-order-history-search.module';
import { ConfigModule, RoutingConfig } from '@spartacus/core';

@NgModule({
  imports: [
    CommonModule,
    KnBrOrderHistorySearchModule,
    KnBrOrderHistorySearchResultModule,
    ConfigModule.withConfig({
      routing: {
        routes: {
          orders: {
            paths: ['customer-360/orders'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrOrderHistoryModule {}
