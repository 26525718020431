import { Action } from '@ngrx/store';

export enum KnBrQuoteContextActionTypes {
  KnBrQuoteContextLoadAction = '[QuoteContext] Load ',
  KnBrQuoteContextLoadSuccessAction = '[QuoteContext] Load Success',
  KnBrQuoteContextAddUpdateAction = '[QuoteContext] ADD|UPDATE',
  KnBrQuoteContextRemoveAction = '[QuoteContext] Remove',
  KnBrQuoteContextResetAction = '[QuoteContext] Reset',
}

export class KnBrQuoteLoadContext implements Action {
  readonly type = KnBrQuoteContextActionTypes.KnBrQuoteContextLoadAction;
}
export class KnBrQuoteLoadSuccessContext implements Action {
  readonly type = KnBrQuoteContextActionTypes.KnBrQuoteContextLoadSuccessAction;
  constructor(public payload: string) {}
}

export class KnBrQuoteAddUpdateContext implements Action {
  readonly type = KnBrQuoteContextActionTypes.KnBrQuoteContextAddUpdateAction;
  constructor(public payload: string) {}
}
export class KnBrQuoteRemoveContext implements Action {
  readonly type = KnBrQuoteContextActionTypes.KnBrQuoteContextRemoveAction;
  constructor(public payload?: string) {}
}

export class KnBrQuoteResetContext implements Action {
  readonly type = KnBrQuoteContextActionTypes.KnBrQuoteContextResetAction;
  constructor(public payload?: string) {}
}
export type KnBrQuoteContextActions =
  | KnBrQuoteAddUpdateContext
  | KnBrQuoteRemoveContext
  | KnBrQuoteResetContext
  | KnBrQuoteLoadContext
  | KnBrQuoteLoadSuccessContext;
