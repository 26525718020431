import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { UserAccountService } from '@spartacus/user/account/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { take } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { environment } from 'src/environments/environment';
declare function ClickwrapSubmitData(firstName: string, lastName: string, email: string, GroupKey);
declare function clickwrapData(SiteAccessId, GroupKey);
@Component({
  selector: 'knbr-sloan-policy-reacceptance',
  //standalone: true,
  templateUrl: './sloan-policy-reacceptance.component.html',
  styleUrl: './sloan-policy-reacceptance.component.scss'
})
export class SloanPolicyReacceptanceComponent implements OnInit {
  reacceptanceForm: UntypedFormGroup;
  disableSubmitBtn = false;
  sloantermsofuse = environment.sloantermsofuse;
  sloanprivacypolicy = environment.sloanprivacypolicy;
  SiteAccessId = environment.siteAccessId;
  GroupKey = environment.groupKey;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  termsofuse: boolean = false;
  Privacypolicy: boolean = false;
  user$ = this.userService.get();
  constructor(protected fb: UntypedFormBuilder,
    private ngbActiveModal: NgbActiveModal,
    public authService: AuthService,
    protected globalMessageService: GlobalMessageService,
    private userService: UserAccountService,
    protected knbrcommonservice: KnBrCommonService
  ) {
    clickwrapData(this.SiteAccessId, this.GroupKey);
    this.knbrcommonservice.checkpactsafedata$.subscribe(res => {
      this.termsofuse = res[environment.termsofuse];
      this.Privacypolicy = res[environment.Privacypolicy];
    });
    this.user$.pipe(take(2)).subscribe((user: any) => {
      this.firstName = user?.firstName;
      this.lastName = user?.lastName;
      this.email = user?.uid;
      if (user?.uid.includes((environment.BASE_SITE[0]).toLowerCase())) {
        this.email = user?.uid.split((environment.BASE_SITE[0] + '|').toLowerCase())[1];
      }
    });

  }
  ngOnInit(): void {
    this.reacceptanceForm = this.fb.group(
      {
        //acceptanceCheckbox: [false],
      }
    );
  }
  onSubmit() {
    //if (this.reacceptanceForm.valid) {
    if (this.email) {
      ClickwrapSubmitData(this.firstName, this.lastName, this.email, this.GroupKey);
      this.closeModal();
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
      if (this.termsofuse == false && this.Privacypolicy == true) {
        this.globalMessageService.add(
          { key: 'loginForm.termsofusemsg' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      }
      else if (this.termsofuse == true && this.Privacypolicy == false) {
        this.globalMessageService.add(
          { key: 'loginForm.policyacceptmsg' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      } else {
        this.globalMessageService.add(
          { key: 'loginForm.commonmsg' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION
        );
      }
    }
  }
  closeModal() {
    this.ngbActiveModal.dismiss();
  }
}
