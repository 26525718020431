<ng-container *ngIf="order$ | async as order">
  <div class="col-md-12 float-right d-flex justify-content-end">
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div>
  <form [formGroup]="returnOrderForm" (ngSubmit)="onSubmit()">
    <div class="col-lg-12 float-left p-0">
      <h3 class="">
        {{ 'knBrOrderReturn.returnwarningMsg' | cxTranslate }}
      </h3>
      <div class="row">
        <div class="col-md-12 p-0 float-left mt-3">
          <div class="col-md-6 float-left">
            <label class="">{{ 'knBrOrderReturn.reason' | cxTranslate }}</label>
            <ng-select
              [items]="reason$ | async"
              formControlName="reason"
              bindLabel="Text"
              bindValue="Key"
              placeholder="{{ 'quoteCommon.label.status' | cxTranslate }}"
              [searchable]="false"
              [clearable]="false"
              [loading]="isReasonsLoading"
              bindLabel="Text"
              bindValue="Key"
            >
            </ng-select>
          </div>
          <div class="col-md-6 float-left">
            <label for="notes">{{ 'knBrOrderReturn.notes' | cxTranslate }}</label>
            <textarea
              formControlName="notes"
              class="form-control"
              placeholder="{{ 'knBrOrderReturn.notes' | cxTranslate }}"
              id="notes"
            ></textarea>
          </div>
        </div>
        <div class="col-md-12 p-0 float-left mt-3 mb-2">
          <div class="col-md-6 float-left">
            <span>{{ 'knBrOrderReturn.orderNumber' | cxTranslate }} </span><span>{{ order.code }}</span>
          </div>
          <div class="col-md-6 float-left">
            <span>{{ 'knBrOrderReturn.poNumber' | cxTranslate }} </span><span>{{ order.purchaseOrderNumber }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 float-left p-0 pt-3 d-flex justify-content-end">
      <button
        *ngIf="returnType === 'RMA'"
        type="submit"
        class="col-lg-3 col-md-4 col-12 btn btn-primary"
        [disabled]="!returnedItems || returnedItems.length < 1"
      >
        {{ 'knBrOrderReturn.button.rma' | cxTranslate }}
      </button>

      <button
        *ngIf="returnType === 'ZRRE'"
        type="submit"
        class="col-lg-3 col-md-4 col-12 btn btn-primary"
        [disabled]="!returnedItems || returnedItems.length < 1"
      >
        {{ 'knBrOrderReturn.button.zrre' | cxTranslate }}
      </button>
    </div>
    <ng-container *ngIf="order.consignments">
      <div class="customersearch-list-table border-bottom mt-3 float-left col-lg-12 p-0">
        <div
          class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block"
        >
          <div class="col-md-1 text-center float-left font-text-600 font-sm-size">
            {{ 'knBrOrderReturn.product' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 font-sm-size">
            {{ 'knBrOrderReturn.code' | cxTranslate }}
          </div>
          <div class="col-md-3 float-left font-text-600 font-sm-size">
            {{ 'knBrOrderReturn.description' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 font-sm-size">
            {{ 'knBrOrderReturn.price' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 font-sm-size">
            {{ 'knBrOrderReturn.qty' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 font-sm-size">
            {{ 'knBrOrderReturn.returnQty' | cxTranslate }}
          </div>
        </div>
        <ng-container *ngFor="let consignment of order.consignments">
          <knbr-return-order-item
            *ngFor="let item of consignment.entries"
            [item]="item"
            (quantityChange)="onQuantityChange($event)"
          >
          </knbr-return-order-item>
        </ng-container>
      </div>
    </ng-container>

    <div class="col-12 float-left p-0 pt-3 d-flex justify-content-end">
      <button
        *ngIf="returnType === 'RMA'"
        type="submit"
        class="col-lg-3 col-md-4 col-12 btn btn-primary"
        [disabled]="!returnedItems || returnedItems.length < 1"
      >
        {{ 'knBrOrderReturn.button.rma' | cxTranslate }}
      </button>

      <button
        *ngIf="returnType === 'ZRRE'"
        type="submit"
        class="col-lg-3 col-md-4 col-12 btn btn-primary"
        [disabled]="!returnedItems || returnedItems.length < 1"
      >
        {{ 'knBrOrderReturn.button.zrre' | cxTranslate }}
      </button>
    </div>
  </form>
</ng-container>
