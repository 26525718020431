import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';

import { KnBrQuoteCommentsPopupComponent } from './kn-br-quote-comments-popup.component';

@NgModule({
  declarations: [KnBrQuoteCommentsPopupComponent],
  imports: [CommonModule, FormsModule, I18nModule],
})
export class KnBrQuoteCommentsPopupModule {}
