import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsNavigationComponent } from '@spartacus/core';
import { NavigationNode } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'knbr-kn-br-responsive-category-navigation',
  templateUrl: './kn-br-responsive-category-navigation.component.html',
  styleUrls: ['./kn-br-responsive-category-navigation.component.scss'],
})
export class KnBrResponsiveCategoryNavigationComponent {
  node$: Observable<NavigationNode>;
  data$: Observable<CmsNavigationComponent>;
  constructor(protected modalService: NgbModal) {}

  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }
}
