<div *ngIf="title" class="modal-header col-md-12 float-left p-3">
  <h4 class="modal-title font-size-18 color-font font-text-bold">{{ title | cxTranslate }}</h4>
  <button type="button" class="close pb-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body col-md-12 float-left p-3 border-bottom">
  <ng-container *ngIf="message">
    <label class="label-content">
      {{ message | cxTranslate }}
    </label>
  </ng-container>
  <ng-container *ngIf="innerHtml">
    <div [innerHtml]="innerHtml"></div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary col-md-3" (click)="accept()">
    {{ btnOkText | cxTranslate }}
  </button>
</div>
