import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { KnBrCartQuoteSummaryService } from 'src/app/services/kn-br-cart-quote.service';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-order-import',
  templateUrl: './kn-br-order-import.component.html',
  styleUrls: ['./kn-br-order-import.component.scss'],
})
export class KnBrOrderImportComponent implements OnInit {
  public fileUploadControl = new FileUploadControl()
    .setListVisibility(false)
    .setValidators([FileUploadValidators.filesLimit(1), Validators.required]);
  documentTypes = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

  constructor(
    protected knBrCommonService: KnBrCommonService,
    protected globalMessageService: GlobalMessageService,
    protected knBrCartQuoteSummaryService: KnBrCartQuoteSummaryService
  ) {}

  ngOnInit(): void {}

  downloadCartImportCSV() {
    this.knBrCartQuoteSummaryService.downloadDraftOrderCSV(null);
  }

  onSubmit() {
    if (this.fileUploadControl.value.length > 0) {
      const formData: FormData = new FormData();
      formData.append('file', this.fileUploadControl.value[0]);
      this.knBrCommonService.uploadOrderExcel(formData);
    } else {
      this.globalMessageService.add({ key: 'knBrMessages.uploadFileValidation' }, GlobalMessageType.MSG_TYPE_WARNING);
    }
    this.fileUploadControl.clear();
  }
}
