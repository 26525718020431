import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  OccEndpointsService,
  PRODUCT_REFERENCES_NORMALIZER,
  ProductReference,
  ProductReferencesAdapter,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';

@Injectable()
export class KnBrOccProductReferencesAdapter implements ProductReferencesAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    private customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : KN_BR_CUSTOMER_CURRENT;
    });
  }

  load(productCode: string, referenceType?: string, pageSize?: number): Observable<ProductReference[]> {
    return this.http

      .get(this.getEndpoint(productCode, referenceType, pageSize))
      .pipe(this.converter.pipeable(PRODUCT_REFERENCES_NORMALIZER));
  }

  protected getEndpoint(code: string, reference?: string, pageSize?: number): string {
    return this.occEndpoints.buildUrl('productReferences', {
      urlParams: {
        productCode: code,
        customerId: this.customerId,
      },
      queryParams: { referenceType: reference, pageSize },
    });
  }
}
