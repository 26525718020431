import { Component, OnInit } from '@angular/core';
import { CardComponent } from '@spartacus/storefront';

@Component({
  selector: 'knbr-address-card',
  templateUrl: './kn-br-address-card.component.html',
  styleUrls: ['./kn-br-address-card.component.scss'],
})
export class KnBrAddressCardComponent extends CardComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
