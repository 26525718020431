import { Action } from '@ngrx/store';

export enum KnBrAddressActionTypes {
  KnBrOneTimeAddressAction = '[OneTimeAddress] ',
  KnBrOneTimeAddressSuccessAction = '[OneTimeAddress]  Success',
  KnBrOneTimeAddressFailureAction = '[OneTimeAddress]  Failure',
}

/**
 * Action to  One Time Address
 */
export class KnBrOneTimeAddress implements Action {
  readonly type = KnBrAddressActionTypes.KnBrOneTimeAddressAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful One Time Address
 */
export class KnBrOneTimeAddressSuccess implements Action {
  readonly type = KnBrAddressActionTypes.KnBrOneTimeAddressSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure One Time Address
 */
export class KnBrOneTimeAddressFailure implements Action {
  readonly type = KnBrAddressActionTypes.KnBrOneTimeAddressFailureAction;
  constructor(public payload: Error) {}
}

export type KnBrOrderReturnActions = KnBrOneTimeAddress | KnBrOneTimeAddressSuccess | KnBrOneTimeAddressFailure;
