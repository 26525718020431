import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService, RoutingService } from '@spartacus/core';
import { FileSaverService } from 'ngx-filesaver';
import { BehaviorSubject, Observable } from 'rxjs';
import { KN_BR_AUTHENTICATE, KN_BR_CUSTOMER_CURRENT, KN_BR_DRAFT_ORDER_CHECKOUT_VALIDATION, KN_BR_ORDER_STATUSES, KN_BR_QUOTE_APPROVER_ACTION, KN_BR_QUOTE_STATUSES, KN_BR_RESET_PASSWORD, KNBR_CANCEL_ORDER_STATUS_LIST, KNBR_CREATE_SERVICE_TICKET, KNBR_DRAFT_ORDER_ATTACHMENT_UPLOAD, KNBR_GET_JOB_FUNCTIONS, KNBR_GET_LANDING_PAGE, KNBR_INVOICE_DOCUMENT_TYPE_LIST, KNBR_INVOICE_FILTER_BY_LIST, KNBR_INVOICE_STATUS_LIST, KNBR_MY_ACCOUNT_PRICE_LIST, KNBR_ORDER_ACTIONS, KNBR_ORDER_CREDIT_STATUS_LIST, KNBR_ORDER_DELIVERY_BLOCK_LIST, KNBR_ORDER_IMPORT, KNBR_ORDER_RETURN_REASON, KNBR_ORDER_STATUS_LIST, KNBR_ORDER_TYPE_LIST, KNBR_QUOTE_ATTACHMENT_UPLOAD, KNBR_QUOTE_DOWNLOAD, KNBR_RETURN_ORDER_STATUS_LIST, KNBR_SERVICE_TICKET_CATEGORY_LIST } from 'src/constants/api.endpoints.constant';
import { KnBrQuotesService } from 'src/feature-libs/kn-br-cart/saved-carts/core/facade/kn-br-quotes.service';
import { KnBrQuoteContextService } from 'src/feature-libs/kn-br-cart/saved-carts/core/facade/kn-br-quote-context.service';
import { KnBrCartContextService } from 'src/feature-libs/kn-br-cart/base/core/facade/kn-br-cart-context.service';
import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';
import { Statuses } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';


@Injectable({
  providedIn: 'root',
})
export class KnBrCommonService {
  orderStatusesResult$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  sharePDPQuantity$: BehaviorSubject<number> = new BehaviorSubject(0);
  quoteStatusesResult$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  selectCartEntry$: BehaviorSubject<number[]> = new BehaviorSubject(null);
  approverStatusSuccess$: BehaviorSubject<string> = new BehaviorSubject(null);
  downloadFlagResult$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  quoteListSelectedProductResult$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  approverStatusFailure$: BehaviorSubject<string> = new BehaviorSubject(null);
  orderStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  cancelOrderStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  orderReturnReasonList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  returnOrderStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  invoiceStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  invoiceFilterByList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  invoiceDocumentTypeList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  orderActionsList$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  redirectionDetail$: BehaviorSubject<any> = new BehaviorSubject(null);
  serviceTicketCateogryList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  jobFunctionList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  checkoutValidationResponse$: BehaviorSubject<any> = new BehaviorSubject(null);
  orderTypeList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  creditStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  deliveryBlockList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);

  private isLoggedInSubject = new BehaviorSubject<boolean>(true);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  checkpactsafedata$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  customerId: string;
  quoteId: string;
  cartId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    private globalMessageService: GlobalMessageService,
    private routingService: RoutingService,
    private fileSaver: FileSaverService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrQuoteService: KnBrQuotesService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected activeCartService: ActiveCartFacade,
    protected multiCartService: MultiCartFacade,
    private knBrCartContextService: KnBrCartContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : KN_BR_CUSTOMER_CURRENT;
    });
    this.knBrQuoteContextService.get$.subscribe((response) => {
      this.quoteId = response ? response : null;
    });
    this.activeCartService.getActiveCartId().subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }

  setLoggedIn(status: boolean): void {
    this.isLoggedInSubject.next(status);
  }

  isLoggedIn(): boolean {
    return this.isLoggedInSubject.value;
  }

  getOrderStatusesList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(KN_BR_ORDER_STATUSES(customerId))).subscribe((response: any) => {
      this.orderStatusesResult$.next(response as Statuses[]);
    });
  }

  loadOrderStatuses$(): Observable<Statuses[]> {
    return this.orderStatusesResult$.asObservable();
  }

  getQuoteStatusesList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(KN_BR_QUOTE_STATUSES(customerId))).subscribe((response: any) => {
      this.quoteStatusesResult$.next(response as Statuses[]);
    });
  }

  loadQuoteStatuses$(): Observable<Statuses[]> {
    return this.quoteStatusesResult$.asObservable();
  }

  setPDPQuantity(quantity: number) {
    this.sharePDPQuantity$.next(quantity);
  }

  loadPDPQuantity(): Observable<number> {
    return this.sharePDPQuantity$.asObservable();
  }

  setCartEntry(quantity: number[]) {
    this.selectCartEntry$.next(quantity);
  }

  loadCartEntry(): Observable<number[]> {
    return this.selectCartEntry$.asObservable();
  }

  updateApproverStatus(payload) {
    this.httpClient
      .post(this.endPointService.buildUrl(KN_BR_QUOTE_APPROVER_ACTION, { queryParams: { ...payload } }), null)
      .subscribe(
        (response: any) => {
          this.approverStatusSuccess$.next(response);
        },
        (error) => {
          this.approverStatusFailure$.next(error);
        }
      );
  }

  resetPassword(userId) {
    this.httpClient.get(this.endPointService.buildUrl(KN_BR_RESET_PASSWORD, { queryParams: { userId } })).subscribe(
      (response: any) => {
        this.globalMessageService.add({ raw: response?.message }, GlobalMessageType.MSG_TYPE_CONFIRMATION);

        this.routingService.go({ cxRoute: 'login' });
      },
      () => {
        this.globalMessageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    );
  }

  downloadQuote(quoteId, multiple = false) {
    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application/pdf; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_QUOTE_DOWNLOAD, {
          urlParams: {
            customerId: this.customerId,
            quoteId,
          },
        }),
        HTTPOptions
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (multiple) {
            this.fileSaver.save(response.body as any, 'Quotes.zip');
          } else {
            let fileName: string;
            if (response.headers) {
              fileName = this.getFileNameFromResponse(response);
            }
            if (!fileName) {
              fileName = quoteId + '.pdf';
            }
            this.fileSaver.save(response.body as any, fileName);
          }
          this.globalMessageService.add(
            { key: 'knBrMessages.downloadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        () => {
          this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  getFileNameFromResponse(response: HttpResponse<any>) {
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
  }

  setDownloadFlag(downloadFlag: boolean) {
    this.downloadFlagResult$.next(downloadFlag);
  }

  loadDownloadFlag$(): Observable<boolean> {
    return this.downloadFlagResult$.asObservable();
  }
  setQuoteListSelectedProducts(products: string[]) {
    this.quoteListSelectedProductResult$.next(products);
  }
  loadQuoteListSelectedProducts$(): Observable<string[]> {
    return this.quoteListSelectedProductResult$.asObservable();
  }

  uploadQuoteAttachment(formData) {
    this.globalMessageService.add({ key: 'knBrMessages.uploadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    this.httpClient
      .post(
        this.endPointService.buildUrl(KNBR_QUOTE_ATTACHMENT_UPLOAD, {
          urlParams: {
            customerId: this.customerId,
            quoteId: this.quoteId,
          },
        }),
        formData
      )
      .subscribe(
        () => {
          this.knBrQuoteService.get(this.quoteId);
          this.globalMessageService.add(
            { key: 'knBrMessages.uploadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        () => {
          this.globalMessageService.add({ key: 'knBrMessages.uploadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  deleteAttachment(fileName) {
    this.httpClient
      .delete(
        this.endPointService.buildUrl(KNBR_QUOTE_ATTACHMENT_UPLOAD, {
          urlParams: {
            customerId: this.customerId,
            quoteId: this.quoteId,
          },
          queryParams: { fileName },
        })
      )
      .subscribe(
        () => {
          this.knBrQuoteService.get(this.quoteId);
          this.globalMessageService.add(
            { key: 'knBrMessages.attachmentRemovedSuccess' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        () => {
          this.globalMessageService.add(
            { key: 'knBrMessages.attachmentRemovedFailed' },
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      );
  }

  uploadDraftOrderAttachment(formData) {
    this.globalMessageService.add({ key: 'knBrMessages.uploadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    this.httpClient
      .post(
        this.endPointService.buildUrl(KNBR_DRAFT_ORDER_ATTACHMENT_UPLOAD, {
          urlParams: {
            customerId: this.customerId,
            cartId: this.cartId,
          },
        }),
        formData
      )
      .subscribe(
        () => {
          this.multiCartService.loadCart({
            userId: null,
            cartId: this.cartId,
            extraData: {
              active: true,
            },
          });
          this.globalMessageService.add(
            { key: 'knBrMessages.uploadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        () => {
          this.globalMessageService.add({ key: 'knBrMessages.uploadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  deleteDraftOrderAttachment(fileName) {
    this.httpClient
      .delete(
        this.endPointService.buildUrl(KNBR_DRAFT_ORDER_ATTACHMENT_UPLOAD, {
          urlParams: {
            customerId: this.customerId,
            cartId: this.cartId,
          },
          queryParams: { fileName },
        })
      )
      .subscribe(
        () => {
          this.multiCartService.loadCart({
            userId: null,
            cartId: this.cartId,
            extraData: {
              active: true,
            },
          });
          this.globalMessageService.add(
            { key: 'knBrMessages.attachmentRemovedSuccess' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        () => {
          this.globalMessageService.add(
            { key: 'knBrMessages.attachmentRemovedFailed' },
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      );
  }

  checkIsValidUser(username) {
    const reqParams = {
      userId: username,
    };

    return this.httpClient.post(this.endPointService.buildUrl(KN_BR_AUTHENTICATE, { queryParams: reqParams }), null);
  }

  getOrderStatusList(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(KNBR_ORDER_STATUS_LIST(customerId)))
      .subscribe((response: any) => {
        this.orderStatusList$.next(response as Statuses[]);
      });
  }

  loadOrderStatusList$(): Observable<Statuses[]> {
    return this.orderStatusList$.asObservable();
  }

  loadInvoiceStatusList$(): Observable<Statuses[]> {
    return this.invoiceStatusList$.asObservable();
  }

  getInvoiceStatusList() {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_INVOICE_STATUS_LIST(this.customerId)))
      .subscribe((response: any) => {
        this.invoiceStatusList$.next(response as Statuses[]);
      });
  }

  loadInvoiceFilterByList$(): Observable<Statuses[]> {
    return this.invoiceFilterByList$.asObservable();
  }

  getInvoiceFilterByList() {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_INVOICE_FILTER_BY_LIST(this.customerId)))
      .subscribe((response: any) => {
        this.invoiceFilterByList$.next(response as Statuses[]);
      });
  }

  loadInvoiceDocumentTypeList$(): Observable<Statuses[]> {
    return this.invoiceDocumentTypeList$.asObservable();
  }

  getInvoiceDocumentTypeList() {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_INVOICE_DOCUMENT_TYPE_LIST(this.customerId)))
      .subscribe((response: any) => {
        this.invoiceDocumentTypeList$.next(response as Statuses[]);
      });
  }
  getCreditStatusList(customerId: string) {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_ORDER_CREDIT_STATUS_LIST(customerId)))
      .subscribe((response: any) => {
        this.creditStatusList$.next(response as Statuses[]);
      });
  }
  getOrderTypeList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(KNBR_ORDER_TYPE_LIST(customerId))).subscribe((response: any) => {
      this.orderTypeList$.next(response as Statuses[]);
    });
  }
  getDeliveryBlockList(customerId: string) {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_ORDER_DELIVERY_BLOCK_LIST(customerId)))
      .subscribe((response: any) => {
        this.deliveryBlockList$.next(response as Statuses[]);
      });
  }

  loadOrderTypeList$(): Observable<Statuses[]> {
    return this.orderTypeList$.asObservable();
  }

  loadCreditStatusList$(): Observable<Statuses[]> {
    return this.creditStatusList$.asObservable();
  }

  loadDeliveryBlockList$(): Observable<Statuses[]> {
    return this.deliveryBlockList$.asObservable();
  }
  getReturnOrderStatusList(customerId: string) {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_RETURN_ORDER_STATUS_LIST, {
          urlParams: {
            customerId,
          },
        })
      )
      .subscribe((response: any) => {
        this.returnOrderStatusList$.next(response as Statuses[]);
      });
  }

  loadReturnOrderStatusList$(): Observable<Statuses[]> {
    return this.returnOrderStatusList$.asObservable();
  }

  getCancelOrderStatusList() {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_CANCEL_ORDER_STATUS_LIST(this.customerId)))
      .subscribe((response: any) => {
        this.cancelOrderStatusList$.next(response as Statuses[]);
      });
  }

  loadCancelOrderStatusList$(): Observable<Statuses[]> {
    return this.cancelOrderStatusList$.asObservable();
  }

  getOrderReturnReasonList() {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_ORDER_RETURN_REASON(this.customerId)))
      .subscribe((response: any) => {
        this.orderReturnReasonList$.next(response as Statuses[]);
      });
  }

  loadOrderReturnReasonList$(): Observable<Statuses[]> {
    return this.orderReturnReasonList$.asObservable();
  }

  getOrderActionsList(orderCode: string) {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_ORDER_ACTIONS, {
          urlParams: {
            customerId: this.customerId,
            code: orderCode,
          },
        })
      )
      .subscribe((response: any) => {
        this.orderActionsList$.next(response as string[]);
      });
  }

  loadOrderActionsList$(): Observable<string[]> {
    return this.orderActionsList$.asObservable();
  }

  uploadOrderExcel(formData) {
    this.globalMessageService.add({ key: 'knBrMessages.uploadInProgress' }, GlobalMessageType.MSG_TYPE_INFO, 15000);

    this.httpClient.post(this.endPointService.buildUrl(KNBR_ORDER_IMPORT(this.customerId), null), formData).subscribe(
      (response: any) => {
        if (response && response.code) {
          this.knBrCartContextService.set(response.code);
          this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_INFO);
          this.globalMessageService.add(
            { key: 'knBrMessages.uploadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
          if (response.errorMessage && response.errorMessage.length > 0) {
            response.errorMessage.forEach((error: any) => {
              if (error.message) {
                this.globalMessageService.add(
                  { raw: error.message },
                  error.type === 'ERROR' ? GlobalMessageType.MSG_TYPE_ERROR : GlobalMessageType.MSG_TYPE_WARNING
                );
              }
            });
          }
          this.routingService.go({ cxRoute: 'cartDetails', params: { code: response.code } });
        }
      },
      (errorres) => {
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_INFO);
        const errors = (errorres?.message || errorres?.details || []) as Array<any>;
        if (errors.length > 0) {
          errors.forEach((error) => {
            const type = error.type.toLowerCase();
            if (type.indexOf('warning') !== -1) {
              this.globalMessageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
            } else if (type.indexOf('error') !== -1) {
              this.globalMessageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
            }
          });
        } else {
          this.globalMessageService.add({ key: 'knBrMessages.uploadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      }
    );
  }

  getRedirectionDetail() {
    return this.httpClient.get(this.endPointService.buildUrl(KNBR_GET_LANDING_PAGE));
  }

  loadRedirectionDetail$(): Observable<any> {
    return this.redirectionDetail$.asObservable();
  }

  getServiceTicketCategoryList() {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_SERVICE_TICKET_CATEGORY_LIST, {
          urlParams: {
            customerId: this.customerId,
          },
        })
      )
      .subscribe((response: any) => {
        this.serviceTicketCateogryList$.next(response as Statuses[]);
      });
  }

  loadServiceTicketCategoryList$(): Observable<Statuses[]> {
    return this.serviceTicketCateogryList$.asObservable();
  }

  createServiceTicket(formData, fileData) {
    return this.httpClient.post(
      this.endPointService.buildUrl(KNBR_CREATE_SERVICE_TICKET, {
        urlParams: {
          customerId: this.customerId,
        },
        queryParams: {
          message: formData.message,
          subject: formData.subject,
          ticketCategory: formData.ticketCategory,
        },
      }),
      fileData
    );
  }

  getJobFunctionList() {
    this.httpClient.get(this.endPointService.buildUrl(KNBR_GET_JOB_FUNCTIONS)).subscribe((response: any) => {
      this.jobFunctionList$.next(response);
    });
  }

  loadJobFunctionList$(): Observable<Statuses[]> {
    return this.jobFunctionList$.asObservable();
  }

  sendMyAccountPriceList() {
    return this.httpClient.get(
      this.endPointService.buildUrl(KNBR_MY_ACCOUNT_PRICE_LIST, {
        urlParams: {
          customerId: this.customerId,
        },
      })
    );
  }

  getQuantityBasedOnMoq(quantity, moq) {
    quantity = parseInt(quantity, 10);
    moq = parseInt(moq, 10);
    if (quantity < moq) {
      return moq;
    } else {
      return quantity;
    }
  }

  setSpinner(showLoading: boolean) {
    this.showSpinner$.next(showLoading);
  }

  loadSpinner$(): Observable<boolean> {
    return this.showSpinner$.asObservable();
  }

  loadCheckoutValidationResponse$(): Observable<any> {
    return this.checkoutValidationResponse$.asObservable();
  }

  clearCheckoutValidationResponse() {
    this.checkoutValidationResponse$.next(null);
  }

  validateOnCheckout() {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_CHECKOUT_VALIDATION, {
          urlParams: {
            customerId: this.customerId,
            cartId: this.cartId,
          },
        })
      )
      .subscribe(
        (response: any) => {
          this.checkoutValidationResponse$.next(response as any);
        },
        (err) => console.log('ERROR', err)
      );
  }
  checkPactSafeAPI(siteId, groupKey, signerIdValue) {
    var url = 'https://pactsafe.io/latest?sid=' +
      siteId +
      '&gkey=' + groupKey +
      '&sig=' + signerIdValue;
    return fetch(url)
      .then(response => {
        // Check if the response is okay
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        // Return the parsed JSON data
        return this.checkpactsafedata$.next(data);
      })
  }
}
