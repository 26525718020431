import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule, CmsConfig } from '@spartacus/core';
import { KnBrQuoteDeleteItemsComponent } from './kn-br-quote-delete-items.component';

@NgModule({
  declarations: [KnBrQuoteDeleteItemsComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteDeleteItemsComponent: {
          component: KnBrQuoteDeleteItemsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrQuoteDeleteItemsModule {}
