import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';

import { KnBrLoginApproverComponent } from './kn-br-login-approver.component';

@NgModule({
  declarations: [KnBrLoginApproverComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrSellerApproverMessageComponent: {
          component: KnBrLoginApproverComponent,
        },
      },
    } as CmsConfig),
    RouterModule.forChild([
      {
        data: { pageLabel: '/login/approver', cxRoute: 'loginApprover' },
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          loginApprover: {
            paths: ['login/approver'],
            protected: false,
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrLoginApproverModule {}
