<div class="modal-header d-flex align-items-center doc-viewer-modal-header">
  <h4 class="modal-title">{{ attachmentName }}</h4>
  <button type="button" class="btn reset pl-4 pr-4 mr-3 pb-1 pt-1 font-lg-size mr-left-auto" (click)="downloadFile()">
    <i class="fas fa-download" aria-hidden="true"></i>
  </button>
  <button type="button" class="close margin-zero" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<ngx-doc-viewer [url]="url" viewer="google" class="doc-viewer"> </ngx-doc-viewer>
