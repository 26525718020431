import { ChangeDetectionStrategy, Component } from '@angular/core';
import { B2BUser } from '@spartacus/core';
import { ItemService, UserDetailsComponent, UserItemService } from '@spartacus/organization/administration/components';
import { B2BUserService } from '@spartacus/organization/administration/core';
import { Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'cx-org-user-details',
  templateUrl: './kn-br-user-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ItemService,
      useExisting: UserItemService,
    },
  ],
  // tslint:disable-next-line: no-host-metadata-property
  host: { class: 'content-wrapper' },
})
export class KnBrUserDetailsComponent extends UserDetailsComponent {
  constructor(protected itemService: ItemService<B2BUser>, protected b2bUserService: B2BUserService) {
    super(itemService, b2bUserService);
  }
}
