<ng-container *ngIf="paginate">
  <span class="color-font">{{
    'knBrMessages.browseMsg'
      | cxTranslate
        : {
            records: recordsShown,
            total: paginate.totalResults,
            type: recordType
          }
  }}</span>
</ng-container>
