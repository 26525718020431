import { Injectable } from '@angular/core';
import { Converter, Occ, Product } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class ProductPrettyNameNormalizer implements Converter<Occ.Product, Product> {
  convert(source: Occ.Product, target?: any): Product {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    if (source.name) {
      target.prettyName = this.normalize(source.name);
    }
    return target;
  }

  protected normalize(name: string): string {
    return name.replace(/\//g, '-');
  }
}
