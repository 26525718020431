import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'kn-br-back-button',
  templateUrl: './kn-br-back-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrBackButtonComponent {
  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
