<div class="col-md-12 p-3 float-left mt-3 address-form">
  <form (ngSubmit)="verifyAddress()" [formGroup]="addressForm">
    <div class="float-left col-md-12 pb-2 p-0 d-flex align-items-center">
      <div class="float-left col-md-3 pl-0">
        <label>
          <span class="label-content required"
            >{{ 'knbrAddressForm.label.name' | cxTranslate }}<span class="text-danger ml-1">*</span>
          </span>
        </label>
      </div>

      <div class="float-left" [class.col-md-9]="isCheckOut" [class.col-md-6]="!isCheckOut">
        <input
          class="form-control"
          type="text"
          placeholder="{{ 'knbrAddressForm.placeholder.name' | cxTranslate }}"
          formControlName="firstName"
        />
        <cx-form-errors [control]="addressForm.get('firstName')"></cx-form-errors>
      </div>
    </div>

    <div class="float-left col-md-12 pb-2 p-0 d-flex align-items-center">
      <div class="float-left col-md-3 pl-0">
        <label>
          <span class="label-content required">{{ 'knbrAddressForm.label.bldg' | cxTranslate }}</span>
        </label>
      </div>

      <div class="float-left" [class.col-md-9]="isCheckOut" [class.col-md-6]="!isCheckOut">
        <input
          class="form-control"
          type="text"
          placeholder="{{ 'knbrAddressForm.placeholder.bldg' | cxTranslate }}"
          formControlName="bldg"
        />
        <cx-form-errors [control]="addressForm.get('bldg')"></cx-form-errors>
      </div>
    </div>

    <div class="float-left col-md-12 pb-2 p-0 d-flex align-items-center">
      <div class="float-left col-md-3 pl-0">
        <label>
          <span class="label-content required"
            >{{ 'knbrAddressForm.label.houseNumberAndStreetName' | cxTranslate
            }}<span class="text-danger ml-1">*</span></span
          >
        </label>
      </div>

      <div class="float-left" [class.col-md-3]="isCheckOut" [class.col-md-2]="!isCheckOut">
        <input
          class="form-control"
          type="text"
          placeholder="{{ 'knbrAddressForm.placeholder.houseNumber' | cxTranslate }}"
          formControlName="houseNumber"
        />
        <cx-form-errors [control]="addressForm.get('houseNumber')"></cx-form-errors>
      </div>

      <div class="float-left" [class.col-md-6]="isCheckOut" [class.col-md-4]="!isCheckOut">
        <input
          class="form-control"
          type="text"
          placeholder="{{ 'knbrAddressForm.placeholder.streetName' | cxTranslate }}"
          formControlName="line1"
        />
        <cx-form-errors [control]="addressForm.get('line1')"></cx-form-errors>
      </div>
    </div>

    <div class="float-left col-md-12 pb-2 p-0 d-flex align-items-center">
      <div class="float-left col-md-3 pl-0">
        <label>
          <span class="label-content required"
            >{{ 'knbrAddressForm.label.cityAndPostalCode' | cxTranslate }}<span class="text-danger ml-1">*</span></span
          >
        </label>
      </div>

      <div class="float-left" [class.col-md-3]="isCheckOut" [class.col-md-2]="!isCheckOut">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'addressForm.city.placeholder' | cxTranslate }}"
          formControlName="town"
        />
        <cx-form-errors [control]="addressForm.get('town')"></cx-form-errors>
      </div>

      <ng-container *ngIf="regions$ | async as regions" formGroupName="region">
        <ng-container *ngIf="regions.length !== 0">
          <div class="float-left" [class.col-md-3]="isCheckOut" [class.col-md-2]="!isCheckOut">
            <ng-select
              class="region-select"
              formControlName="isocode"
              [searchable]="true"
              [clearable]="false"
              [items]="regions"
              bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
              bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
              placeholder="{{ 'knbrAddressForm.placeholder.select' | cxTranslate }}"
            >
            </ng-select>
            <cx-form-errors [control]="addressForm.get('region.isocode')"></cx-form-errors>
          </div>
        </ng-container>
      </ng-container>

      <div class="float-left" [class.col-md-3]="isCheckOut" [class.col-md-2]="!isCheckOut">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'knbrAddressForm.placeholder.postalCode' | cxTranslate }}"
          formControlName="postalCode"
        />
        <cx-form-errors [control]="addressForm.get('postalCode')"></cx-form-errors>
      </div>
    </div>

    <div class="float-left col-md-12 pb-2 p-0 d-flex align-items-center">
      <div class="float-left col-md-3 pl-0">
        <label>
          <span class="label-content required"
            >{{ 'addressForm.country' | cxTranslate }}<span class="text-danger ml-1">*</span></span
          >
        </label>
      </div>

      <div class="float-left" formGroupName="country" [class.col-md-9]="isCheckOut" [class.col-md-6]="!isCheckOut">
        <ng-container *ngIf="countries$ | async as countries">
          <div *ngIf="countries.length !== 0">
            <ng-select
              class="country-select"
              formControlName="isocode"
              [searchable]="true"
              [clearable]="false"
              [items]="countries"
              bindLabel="name"
              bindValue="isocode"
              placeholder="{{ 'knbrAddressForm.placeholder.select' | cxTranslate }}"
              (change)="countrySelected($event)"
            >
            </ng-select>
            <cx-form-errors [control]="addressForm.get('country.isocode')"></cx-form-errors>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="float-left col-md-12 pb-2 p-0 d-flex align-items-center">
      <div class="float-left col-md-3 pl-0">
        <label>
          <span class="label-content">{{ 'knbrAddressForm.label.email' | cxTranslate }}</span>
        </label>
      </div>

      <div class="float-left" [class.col-md-9]="isCheckOut" [class.col-md-6]="!isCheckOut">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'knbrAddressForm.placeholder.email' | cxTranslate }}"
          formControlName="email"
        />
      </div>
    </div>

    <div class="float-left col-md-12 pb-2 p-0 d-flex align-items-center">
      <div class="float-left col-md-3 pl-0">
        <label>
          <span class="label-content">{{ 'knbrAddressForm.label.telephone' | cxTranslate }}</span>
        </label>
      </div>

      <div class="float-left" [class.col-md-9]="isCheckOut" [class.col-md-6]="!isCheckOut">
        <input
          type="tel"
          class="form-control"
          placeholder="{{ 'knbrAddressForm.placeholder.telephone' | cxTranslate }}"
          formControlName="phone"
        />
      </div>
    </div>

    <div class="float-left col-md-12 pb-2 p-0 d-flex align-items-center">
      <div class="float-left col-md-3 pl-0">
        <label>
          <span class="label-content">{{ 'knbrAddressForm.label.fax' | cxTranslate }}</span>
        </label>
      </div>

      <div class="float-left" [class.col-md-9]="isCheckOut" [class.col-md-6]="!isCheckOut">
        <input
          type="tel"
          class="form-control"
          placeholder="{{ 'knbrAddressForm.placeholder.fax' | cxTranslate }}"
          formControlName="fax"
        />
      </div>
    </div>

    <div class="float-left col-md-12 pb-2 p-0">
      <div class="float-left pl-0" [class.col-md-6]="isCheckOut" [class.col-md-3]="!isCheckOut">
        <label>
          <span class="label-content"
            >( <span class="text-danger">*</span> ) {{ 'knbrAddressForm.label.mandatoryFields' | cxTranslate }}</span
          >
        </label>
      </div>
    </div>
    <div class="cx-checkout-btns float-left col-md-12 pb-2 p-0">
      <div class="float-left col-md-3">&nbsp;</div>
      <div class="float-left col-md-3" *ngIf="showCancelBtn && !isCheckOut">
        <button class="btn btn-block btn-outline-primary" (click)="back()">
          {{ cancelBtnLabel || ('addressForm.chooseAddress' | cxTranslate) }}
        </button>
      </div>

      <div class="float-left col-md-3" *ngIf="isCheckOut">
        <button class="btn btn-block btn-outline-primary" (click)="activeModal.dismiss()">
          {{ 'knbrAddressForm.button.cancel' | cxTranslate }}
        </button>
      </div>

      <div class="float-left col-md-3" *ngIf="isCheckOut">
        <button class="btn btn-block btn-outline-primary" (click)="oneTimeUse()">
          {{ 'knbrAddressForm.button.onetime' | cxTranslate }}
        </button>
      </div>
      <div class="float-left col-md-3">
        <button class="btn btn-block btn-primary" type="submit">
          {{ actionBtnLabel || ('common.continue' | cxTranslate) }}
        </button>
      </div>
    </div>
  </form>
</div>
