import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, PageMetaModule } from '@spartacus/core';

import { KnBrBreadCrumbComponent } from './kn-br-bread-crumb.component';

@NgModule({
    declarations: [KnBrBreadCrumbComponent],
    imports: [
        CommonModule,
        RouterModule,
        PageMetaModule,
        ConfigModule.withConfig({
            cmsComponents: {
                BreadcrumbComponent: {
                    component: KnBrBreadCrumbComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrBreadCrumbComponent]
})
export class KnBrBreadCrumbModule {}
