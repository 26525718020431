import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'knbr-kn-br-doc-viewer',
  templateUrl: './kn-br-doc-viewer.component.html',
  styleUrls: ['./kn-br-doc-viewer.component.scss'],
})
export class KnBrDocViewerComponent implements OnInit {
  url: string;
  attachmentName: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  downloadFile() {
    window.open(this.url, '_self');
  }
}
