import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule, SpinnerModule } from '@spartacus/storefront';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

import { KnBrAddToQuoteComponent } from './kn-br-add-to-quote.component';
import { KnBrSelectQuoteComponent } from './kn-br-select-quote/kn-br-select-quote.component';

@NgModule({
  declarations: [KnBrAddToQuoteComponent, KnBrSelectQuoteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SpinnerModule,
    UrlModule,
    IconModule,
    KeyboardFocusModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    I18nModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrProductAddToQuoteComponent: {
          component: KnBrAddToQuoteComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrAddToQuoteComponent],
})
export class KnBrAddToQuoteModule {}
