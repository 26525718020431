import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeLeadingZeros',
})
export class RemoveLeadingZerosPipe implements PipeTransform {
  transform(input: string): string {
    return input ? input.replace(/^0+/, '') : input;
  }
}
