import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResetPasswordComponent } from '@spartacus/user/profile/components';
import { KnbrResetPasswordComponentService } from './reset-password-component.service';

@Component({
  selector: 'knbr-kn-br-reset-password',
  templateUrl: './kn-br-reset-password.component.html',
  styleUrls: ['./kn-br-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrResetPasswordComponent extends ResetPasswordComponent {
  constructor(protected service: KnbrResetPasswordComponentService) {
    super(service);
  }
  onSubmit(token: string) {
    this.service.resetPassword(token);
  }
}
