import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { KnBrAddress } from '../models/kn-br-order.model';

@Injectable()
export class KnBrAddressNormalizer implements Converter<Occ.Address, KnBrAddress> {
  convert(source: Occ.Address, target: KnBrAddress): KnBrAddress {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    return target;
  }
}
