<div class="col-md-12 p-0 float-left mt-3 d-md-flex align-items-center">
  <div class="col-md-6 p-0 float-left">{{ 'quickEntry.label.cartLimitNote' | cxTranslate }}</div>
  <div class="col-md-6 p-0 float-left d-md-flex justify-content-end">
    <div class="col-md-4 mr-3 pl-0 pr-0 pt-1">
      <button type="button" (click)="resetForm()" class="btn btn-outline-primary mr-3 col-md-12">
        {{ 'quickOrder.label.resetForm' | cxTranslate }}
      </button>
    </div>

    <div class="col-md-4 mr-3 pl-0 pr-0 pt-1" *ngIf="customerContext && (cartContext || !quoteContext)">
      <kn-br-add-to-cart [type]="'QUICK'" [productList]="allAddedProducts"> </kn-br-add-to-cart>
    </div>

    <div class="col-md-4 pl-0 pr-0 pt-1" *ngIf="customerContext && (!cartContext || quoteContext)">
      <kn-br-add-to-quote [type]="'QUICK'" [productList]="allAddedProducts"> </kn-br-add-to-quote>
    </div>
  </div>
</div>

<div class="customersearch-list-table border-bottom mt-3 float-left col-lg-12 p-0">
  <div class="customersearch-list-titles border-top float-left w-100 p-3 d-none d-sm-none d-lg-block">
    <div class="col-md-1 text-center float-left font-text-600 font-sm-size">
      {{ 'quickEntry.header.lineNo' | cxTranslate }}
    </div>
    <div class="col-md-6 float-left font-text-600 font-sm-size">
      {{ 'quickEntry.header.product' | cxTranslate }}
    </div>
    <div class="col-md-5 float-left font-text-600 font-sm-size">
      {{ 'quickEntry.header.quantity' | cxTranslate }}
    </div>
  </div>
</div>
<form [formGroup]="addProductsForm">
  <ng-container *ngFor="let product of t.controls; let i = index">
    <div
      [formGroup]="product"
      class="bg-white border-bottom float-left w-100 p-3 font-weight-normal"
      *ngIf="!product.controls.showForm.value"
    >
      <div class="col-md-1 p-0 text-center pt-3 float-left font-size color-font">
        {{
          cartContext
            ? ((cartItems$ | async) + (i + 1)) * 10
            : quoteContext
            ? ((quoteItems$ | async) + (i + 1)) * 10
            : (i + 1) * 10
        }}
      </div>
      <div class="col-md-10 p-0 float-left font-size color-font">
        <input
          type="text"
          placeholder="{{ 'quickEntry.header.product' | cxTranslate }}"
          formControlName="productCode"
          (focus)="onEnterInput($event, i)"
          (blur)="searchForProduct(i)"
          (keyup.enter)="searchForProduct(i)"
          class="form-control mb-0"
          [ngClass]="{ 'is-invalid': submitted && addProductsForm.controls.productCode.errors }"
        />
        <span *ngIf="product.controls.showErrorMsg.value" class="text-danger">{{
          'quickEntry.message.productwarnmsg' | cxTranslate
        }}</span>
      </div>
      <div class="col-md-1 float-left title-wrap font-size color-font">
        <button
          type="button"
          tabindex="-1"
          (click)="removeProduct(i)"
          class="btn reset pl-4 pr-4 mr-3 pb-1 pt-1 font-lg-size mr-left-auto"
        >
          <i class="fas fa-times-circle" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div [formGroup]="product" *ngIf="product.controls.showForm.value">
      <div class="bg-white border-bottom float-left w-100 p-3 font-weight-normal">
        <div class="col-md-1 p-0 text-center pt-3 float-left font-size color-font">
          {{
            cartContext
              ? ((cartItems$ | async) + (i + 1)) * 10
              : quoteContext
              ? ((quoteItems$ | async) + (i + 1)) * 10
              : (i + 1) * 10
          }}
        </div>
        <div class="col-md-6 pl-0 float-left font-size color-font">
          <input
            type="text"
            (blur)="searchForProduct(i)"
            formControlName="productCode"
            class="form-control mb-0"
            [ngClass]="{ 'is-invalid': submitted && product.controls.productCode.errors }"
          />
        </div>
        <div class="col-md-4 p-0 float-left font-size color-font">
          <input
            type="text"
            #qty
            formControlName="qty"
            class="form-control mb-0"
            (change)="onQuantityChange(i)"
            [ngClass]="{ 'is-invalid': submitted && product.controls.qty.errors }"
            knBrNumbersOnly
          />
          <span>
            {{ 'quickEntry.message.minorderQty' | cxTranslate }}:
            {{ product.controls.image.value.minOrderQuantity }}</span
          >
        </div>
        <div class="col-md-1 float-left title-wrap font-size color-font">
          <button
            type="button"
            tabindex="-1"
            class="btn reset pl-4 pr-4 mr-3 pb-1 pt-1 font-lg-size mr-left-auto"
            (click)="removeProduct(i)"
          >
            <i class="fas fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</form>

<div class="col-12 p-0 float-left mt-3">
  <div class="col-md-6 p-0 float-right d-md-flex justify-content-end">
    <div class="col-md-4 mr-3 pl-0 pr-0 pt-1">
      <button type="button" (click)="resetForm()" class="col-md-12 btn btn-outline-primary mr-3">
        {{ 'quickOrder.label.resetForm' | cxTranslate }}
      </button>
    </div>

    <div class="col-md-4 mr-3 pl-0 pr-0 pt-1" *ngIf="customerContext && (cartContext || !quoteContext)">
      <kn-br-add-to-cart [type]="'QUICK'" [productList]="allAddedProducts"> </kn-br-add-to-cart>
    </div>

    <div class="col-md-4 pl-0 pr-0 pt-1" *ngIf="customerContext && (!cartContext || quoteContext)">
      <kn-br-add-to-quote [type]="'QUICK'" [productList]="allAddedProducts"> </kn-br-add-to-quote>
    </div>
  </div>
</div>
