import { RouterModule } from '@angular/router';
import { KnBrOrderAcknowledgeComponent } from './kn-br-order-acknowledge.component';
import { ConfigModule, CmsConfig, AuthGuard, I18nModule, UrlModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  declarations: [KnBrOrderAcknowledgeComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrOrderAcknowledgeComponent: {
          component: KnBrOrderAcknowledgeComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrOrderAcknowledgeModule {}
