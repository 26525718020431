import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KnBrCustomerEffects } from './kn-br-customer.effects';
import { customerReducer } from './kn-br-customer.reducer';
import { KN_BR_SEARCH_CUSTOMER_FEATURE } from './kn-br-customer.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_SEARCH_CUSTOMER_FEATURE, customerReducer),
    EffectsModule.forFeature([KnBrCustomerEffects]),
  ],
})
export class KnBrCustomerStateModule {}
