import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '@spartacus/storefront';

import { KnBrQuoteReferenceComponent } from './kn-br-quote-reference.component';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [KnBrQuoteReferenceComponent],
  imports: [CommonModule, FormsModule, SpinnerModule, I18nModule],
})
export class KnBrQuoteReferenceModule {}
