import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ForgotPasswordComponent, ForgotPasswordComponentService } from '@spartacus/user/profile/components';

@Component({
  selector: 'kn-br-forgot-password',
  templateUrl: './kn-br-forgot-password.component.html',
  styleUrls: ['./kn-br-forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrForgotPasswordFormComponent extends ForgotPasswordComponent {
  constructor(protected service: ForgotPasswordComponentService) {
    super(service);
  }
}
