<div class="modal-header d-flex align-items-center p-3">
  <h4 class="modal-title font-size-18 font-text-bold">
    {{ 'knbrCancelOrder.modalTitle' | cxTranslate }}
  </h4>
  <button type="button" class="close pb-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ 'knbrCancelOrder.modalMsg' | cxTranslate }}
  <form [formGroup]="orderReasonForm" (ngSubmit)="cancelOrder()" novalidate>
    <div class="row mt-2">
      <div class="form-contrl col-md-9">
        <ng-select [items]="cancelOrderStatusList$ | async" bindLabel="Text" bindValue="Key" formControlName="reason">
        </ng-select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn color-knbr-secondary color-white" (click)="cancelOrder()">
    {{ 'knbrCancelOrder.yesBtn' | cxTranslate }}
  </button>
  <button type="button" class="btn color-knbr-secondary color-white" (click)="dismiss()">
    {{ 'knbrCancelOrder.noBtn' | cxTranslate }}
  </button>
</div>
