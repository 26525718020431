import { KnBrResetPasswordComponent } from './kn-br-reset-password.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, ConfigModule, CmsConfig, GlobalMessageService, RoutingService } from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { KnbrResetPasswordComponentService } from './reset-password-component.service';
@NgModule({
    declarations: [KnBrResetPasswordComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        I18nModule,
        FormErrorsModule,
        SpinnerModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ResetPasswordComponent: {
                    component: KnBrResetPasswordComponent,
                    providers: [
                        {
                            provide: ResetPasswordComponentService,
                            useClass: KnbrResetPasswordComponentService,
                            deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
                        },
                    ],
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrResetPasswordComponent],
    providers: [
        KnbrResetPasswordComponentService
    ]
})
export class KnBrResetPasswordModule { }
