import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kn-br-quote-partial-item',
  templateUrl: './kn-br-quote-partial-item.component.html',
  styleUrls: ['./kn-br-quote-partial-item.component.scss'],
})
export class KnBrQuotePartialItemComponent implements OnInit {
  @Input() orderRefEntries: any;
  @Input() togglePartialOrder: boolean;
  constructor() {}

  ngOnInit() {}
}
