<div *ngIf="type == 'Return'" class="col-md-12 text-center p-0 mt-3 mb-4">
  <h3>{{ 'knBrOrderReturn.confirmation.returnrequest' | cxTranslate }}</h3>
  <p>{{ 'knBrOrderReturn.confirmation.returnnotes' | cxTranslate }}</p>
  <p>
    {{ 'knBrOrderReturn.confirmation.summary' | cxTranslate }}
    <a role="button" (click)="returnRequestDetails()">{{
      rma | removeLeadingZeros
    }}</a>
  </p>
  <button type="button" class="btn btn-outline-primary" (click)="goToOrderDetails()">
    {{ 'knBrOrderReturn.confirmation.backbuttonlabel' | cxTranslate }}
  </button>
</div>
<div *ngIf="type == 'Cancel'" class="col-md-12 text-center p-0 mt-3 mb-4">
  <p>{{ 'knbrCancelOrder.confirmation.heading' | cxTranslate }}</p>
  <h2>{{ 'knbrCancelOrder.confirmation.summary' | cxTranslate }} {{ orderCode | removeLeadingZeros }}</h2>
</div>

<div *ngIf="type == 'EditOrder'" class="col-md-12 text-center p-0 mt-3 mb-4">
  <p>{{ 'knbrOrderDetails.editOrder.confirmHeading' | cxTranslate }} {{ orderNumber | removeLeadingZeros }}</p>
  <p>{{ 'knbrOrderDetails.editOrder.processMessage' | cxTranslate }}</p>

  <button type="button" class="btn btn-outline-primary" (click)="goToOrderList()">
    {{ 'knbrOrderDetails.editOrder.backButton' | cxTranslate }}
  </button>
</div>
