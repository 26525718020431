import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { KnBrQuoteSaveComponent } from './kn-br-quote-save.component';

@NgModule({
  declarations: [KnBrQuoteSaveComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        QuoteSaveButton: {
          component: KnBrQuoteSaveComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrQuoteSaveComponent],
})
export class KnBrQuoteSaveModule {}
