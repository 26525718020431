<div class="col-md-12 p-0 float-left">
  <form [formGroup]="nacConfigurbaleForm">
    <div class="col-md-12 p-3 float-left bg-light-gray">
      <h3 class="mb-0 font-text-500">{{ 'nacConfigurbaleForm.headings.configurableTemplate' | cxTranslate }}</h3>
    </div>
    <div class="float-left col-md-12 p-0 mt-3">
      <button type="button" class="btn btn-primary col-md-3 mr-2" (click)="openUploadNewTemplateDialog()">
        {{ 'nacConfigurbaleForm.buttons.uploadNewTemplate' | cxTranslate }}
      </button>
      <button type="button" class="btn btn-primary col-md-3" (click)="downloadProductAttributes()">
        {{ 'nacConfigurbaleForm.buttons.downlaodProductAttributes' | cxTranslate }}
      </button>
    </div>
    <div class="col-md-12 p-3 float-left">
      <div class="form-check col-md-12 float-left">
        <input
          class="form-check-input"
          type="radio"
          name="template"
          value="Custom-FeatureNbenefit"
          formControlName="template"
        />
        <label class="form-check-label" for="Custom-FeatureNbenefit"> Custom-FeatureNbenefit </label>
      </div>
      <ng-container
        [ngTemplateOutlet]="this.nacConfigurbaleForm.value.template == 'Custom-FeatureNbenefit' ? subTemplate : ''"
      >
      </ng-container>
      <div class="form-check col-md-12 float-left">
        <input
          class="form-check-input"
          type="radio"
          name="template"
          value="Custom-Boolean Attribute"
          formControlName="template"
        />
        <label class="form-check-label" for="Custom-Boolean Attribute"> Custom-Boolean Attribute </label>
      </div>
      <ng-container
        [ngTemplateOutlet]="this.nacConfigurbaleForm.value.template == 'Custom-Boolean Attribute' ? subTemplate : ''"
      >
      </ng-container>
      <div class="form-check col-md-12 float-left">
        <input class="form-check-input" type="radio" name="template" value="Custom-BAII" formControlName="template" />
        <label class="form-check-label" for="Custom-BAII"> Custom-BAII </label>
      </div>
      <ng-container [ngTemplateOutlet]="this.nacConfigurbaleForm.value.template == 'Custom-BAII' ? subTemplate : ''">
      </ng-container>
    </div>
  </form>
</div>

<ng-template #subTemplate>
  <div class="col-md-12 p-3 float-left mt-1 mb-3 border">
    <div class="col-md-12 p-0 mb-3 float-left">
      <h4 class="mb-0 font-lg-size font-text-500">
        {{ 'nacConfigurbaleForm.headings.subTemplateHeading' | cxTranslate }}
      </h4>
    </div>
    <div class="form-check col-md-12 float-left mb-1">
      <input
        class="form-check-input"
        type="checkbox"
        id="downloadTypeProductAttributes"
        [(ngModel)]="downloadTypeProductAttributes"
      />
      <label class="form-check-label mb-2" for="downloadTypeProductAttributes">
        {{ 'nacConfigurbaleForm.labels.productAttributes' | cxTranslate }}</label
      >
    </div>
    <div class="form-check col-md-12 float-left mb-1">
      <input class="form-check-input" type="checkbox" id="downloadTypeImages" [(ngModel)]="downloadTypeImages" />
      <label class="form-check-label mb-2" for="downloadTypeImages">
        {{ 'nacConfigurbaleForm.labels.images' | cxTranslate }}</label
      >
    </div>
    <div class="form-check col-md-12 float-left mb-1">
      <input
        class="form-check-input"
        type="checkbox"
        id="downloadOtherProductAssets"
        [(ngModel)]="downloadOtherProductAssets"
      />
      <label class="form-check-label mb-2" for="downloadOtherProductAssets">
        {{ 'nacConfigurbaleForm.labels.otherProductAssets' | cxTranslate }}</label
      >
    </div>
    <div class="col-md-12 p-0 mt-2 float-left">
      <button type="button" class="btn btn-primary col-md-2 mr-2" (click)="deleteTemplate()">
        {{ 'nacConfigurbaleForm.buttons.delete' | cxTranslate }}
      </button>
      <button type="button" class="btn btn-primary col-md-2" (click)="openUpdateTemplateDialog()">
        {{ 'nacConfigurbaleForm.buttons.update' | cxTranslate }}
      </button>
    </div>
  </div>
  <div
    class="col-md-12 p-3 float-left mt-1 mb-3 border"
    *ngIf="downloadTypeProductAttributes || downloadTypeImages || downloadOtherProductAssets"
  >
    <div class="form-check col-md-12 float-left">
      <input
        class="form-check-input"
        type="checkbox"
        id="downloadAllTheProducts"
        [(ngModel)]="downloadAllTheProducts"
      />
      <label class="form-check-label mb-2" for="downloadAllTheProducts">
        {{ 'nacConfigurbaleForm.labels.downloadAllTheProducts' | cxTranslate }}</label
      >
    </div>
    <div class="col-md-12 p-0 float-left">
      <div class="col-md-12 p-2 float-left border">
        <file-upload class="d-inline-flex" [accept]="documentTypes" simple [control]="fileUploadControl">
          <ng-template let-control="control" #button>
            <a class="pl-4 pr-4 btn btn-secondary">{{ 'nacConfigurbaleForm.labels.chooseFile' | cxTranslate }}</a>
          </ng-template>
        </file-upload>
        <ng-container *ngIf="fileUploadControl.value.length == 0">
          <span class="ml-3 font-italic">{{ 'nacConfigurbaleForm.labels.noFileChosen' | cxTranslate }}</span>
        </ng-container>
        <ng-container *ngIf="fileUploadControl.value.length > 0">
          <ng-container *ngFor="let file of fileUploadControl.value">
            <span class="ml-3 font-italic">{{ file.name }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="col-md-12 p-0 float-left mt-3">
      <button
        type="button"
        class="btn btn-primary col-md-2"
        *ngIf="!downloadAllTheProducts"
        (click)="openDownloadConfirmationDialog()"
      >
        {{ 'nacConfigurbaleForm.buttons.import' | cxTranslate }}
      </button>
      <button
        type="button"
        class="btn btn-primary col-md-2"
        *ngIf="downloadAllTheProducts"
        (click)="openDownloadConfirmationDialog()"
      >
        {{ 'nacConfigurbaleForm.buttons.download' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-template>
