import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KN_BR_SEARCH_QUOTES_FEATURE, KnBrQuotesState } from 'src/app/store/kn-br-quotes/kn-br-quotes.state';



export const getQuoteListState = createFeatureSelector<KnBrQuotesState>(KN_BR_SEARCH_QUOTES_FEATURE);

export const getQuotes = createSelector(getQuoteListState, (state: KnBrQuotesState) => state.quotes);

export const getQuotesLoader = createSelector(getQuoteListState, (state: KnBrQuotesState) => state.isLoading);

export const getQuotesPaginate = createSelector(getQuoteListState, (state: KnBrQuotesState) => state.pagination);

export const getQuoteSearchCriteria = createSelector(
  getQuoteListState,
  (state: KnBrQuotesState) => state.searchRequest
);
export const getQuote = createSelector(getQuoteListState, (state: KnBrQuotesState) => state.quote);

export const getQuoteSearchDisabled = createSelector(
  getQuoteListState,
  (state: KnBrQuotesState) => state.isSearchDisabled
);

export const getQuoteCreateDisabled = createSelector(
  getQuoteListState,
  (state: KnBrQuotesState) => state.isCreateDisabled
);
export const getQuoteDetailStatuses = createSelector(getQuoteListState, (state: KnBrQuotesState) => state.statuses);

export const getDraftOrder = createSelector(getQuoteListState, (state: KnBrQuotesState) => state.draftOrder);

export const getQuotesSpinner = createSelector(getQuoteListState, (state: KnBrQuotesState) => state.showSpinner);

export const getQuotesDetailSpinner = createSelector(
  getQuoteListState,
  (state: KnBrQuotesState) => state.detailSpinner
);
