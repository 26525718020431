import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';

import { KnBrMessageDialogComponent } from './kn-br-message-dialog.component';

@NgModule({
  declarations: [KnBrMessageDialogComponent],
  imports: [CommonModule, I18nModule],
})
export class KnBrMessageDialogModule {}
