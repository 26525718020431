import { KnBrCustomerContextActions, KnBrCustomerContextActionTypes } from './kn-br-customer-context.actions';
import { KnBrCustomerContextState } from './kn-br-customer-context.state';

export const initialState: KnBrCustomerContextState = {
  customerContext: undefined,
};

export function knBrCustomerContextReducer(
  state = initialState,
  action: KnBrCustomerContextActions
): KnBrCustomerContextState {
  switch (action.type) {
    case KnBrCustomerContextActionTypes.KnBrCustomerContextLoadSuccessAction:
      return { ...state, customerContext: action.payload };
    case KnBrCustomerContextActionTypes.KnBrCustomerContextAddUpdateAction:
      return { ...state, customerContext: action.payload };
    case KnBrCustomerContextActionTypes.KnBrCustomerContextRemoveAction:
      return { ...state, customerContext: null };
    case KnBrCustomerContextActionTypes.KnBrCustomerContextResetAction:
      return { customerContext: null };
    default:
      return state;
  }
}
