import { Action } from '@ngrx/store';

export enum KnBrQuoteReferenceActionTypes {
  KnBrQuoteAddReferenceAction = '[Quote-reference] Add Reference',
  KnBrQuoteAddReferenceSuccessAction = '[Quote-reference] Add Reference Success',
  KnBrQuoteAddReferenceFailureAction = '[Quote-reference] Add Reference Failure',
  KnBrQuoteRemoveReferenceAction = '[Quote-reference] Remove Reference',
  KnBrQuoteRemoveReferenceSuccessAction = '[Quote-reference] Remove Reference Success',
  KnBrQuoteRemoveReferenceFailureAction = '[Quote-reference] Remove Reference Failure',
  KnBrQuoteUpdateReferenceAction = '[Quote-reference] Update Reference',
  KnBrQuoteUpdateReferenceSuccessAction = '[Quote-reference] Update Reference Success',
  KnBrQuoteUpdateReferenceFailureAction = '[Quote-reference] Update Reference Failure',

  KnBrQuoteLoadReferenceAction = '[Quote-reference] Load reference',
  KnBrQuoteLoadReferenceByProductAction = '[Quote-reference] Load reference By Product',
  KnBrQuoteLoadReferenceFailureAction = '[Quote-reference] Load Quote Reference Fail',
  KnBrQuoteLoadReferenceSuccessAction = '[Quote-reference] Load Quote Reference Success',
}

export class KnBrQuoteAddReference implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteAddReferenceAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteAddReferenceSuccess implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteAddReferenceSuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteAddReferenceFailure implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteAddReferenceFailureAction;
  constructor(public error: Error) {}
}

export class KnBrQuoteRemoveReference implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteRemoveReferenceAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteRemoveReferenceSuccess implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteRemoveReferenceSuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteRemoveReferenceFailure implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteRemoveReferenceFailureAction;
  constructor(public error: Error) {}
}

export class KnBrQuoteUpdateReference implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteUpdateReferenceAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteUpdateReferenceSuccess implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteUpdateReferenceSuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteUpdateReferenceFailure implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteUpdateReferenceFailureAction;
  constructor(public error: Error) {}
}

export class KnBrQuoteLoadReference implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceAction;
  constructor(public payload: any) {}
}
export class KnBrQuoteLoadReferenceByProduct implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceByProductAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteLoadReferenceSuccess implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceSuccessAction;
  constructor(public payload: any) {}
}

export class KnBrQuoteLoadReferenceFailure implements Action {
  readonly type = KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceFailureAction;
  constructor(public error: Error) {}
}

export type KnBrQuoteReferenceAction =
  | KnBrQuoteAddReference
  | KnBrQuoteAddReferenceSuccess
  | KnBrQuoteAddReferenceFailure
  | KnBrQuoteRemoveReference
  | KnBrQuoteRemoveReferenceSuccess
  | KnBrQuoteRemoveReferenceFailure
  | KnBrQuoteUpdateReference
  | KnBrQuoteUpdateReferenceSuccess
  | KnBrQuoteUpdateReferenceFailure
  | KnBrQuoteLoadReference
  | KnBrQuoteLoadReferenceByProduct
  | KnBrQuoteLoadReferenceSuccess
  | KnBrQuoteLoadReferenceFailure;
