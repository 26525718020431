import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';

import { KnBrCustomerHeaderModule } from './kn-br-customer-header/kn-br-customer-header.module';
import { KnBrCustomerSearchResultModule } from './kn-br-customer-search-result/kn-br-customer-search-result.module';

@NgModule({
  imports: [
    CommonModule,
    KnBrCustomerSearchResultModule,
    KnBrCustomerHeaderModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/switch-account', cxRoute: 'switchAccount' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          switchAccount: {
            paths: ['switch-account'],
          },
        },
      },
    } as RoutingConfig),
  ],
  declarations: [],
})
export class KnBrCustomerSearchModule {}
