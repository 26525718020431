import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'knbr-kn-br-update-template-dialog',
  templateUrl: './kn-br-update-template-dialog.component.html',
  styleUrls: ['./kn-br-update-template-dialog.component.scss'],
})
export class KnBrUpdateTemplateDialogComponent implements OnInit {
  public fileUploadControl = new FileUploadControl()
    .setListVisibility(false)
    .setValidators([FileUploadValidators.filesLimit(1), Validators.required]);

  documentTypes = '.csv';
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  dismiss() {
    this.activeModal.dismiss();
  }

  download() {}

  update() {
    if (this.fileUploadControl.value.length > 0) {
      this.activeModal.close();
    }
  }
}
