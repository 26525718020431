import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { KnBrReturnOrderDetailsOverviewModule } from './kn-br-return-order-details-overview/kn-br-return-order-details-overview.module';
import { KnBrReturnOrderDetailsItemsModule } from './kn-br-return-order-details-items/kn-br-return-order-details-items.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrReturnOrderDetailsOverviewModule,
    KnBrReturnOrderDetailsItemsModule,
    I18nModule,
    ConfigModule.withConfig({
      routing: {
        routes: {
          returnRequestDetails: {
            paths: ['customer-360/return-request/:returnCode'],
            paramsMapping: { returnCode: 'code' },
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrReturnOrderDetailModule {}
