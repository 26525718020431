import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import { ActiveCartService, SelectiveCartService, MultiCartService } from '@spartacus/cart/base/core';
import { ActiveCartFacade, SelectiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { FeatureConfigService, UserIdService } from '@spartacus/core';

@Component({
  selector: 'cx-cart-item-list',
  templateUrl: './sloan-cart-item-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanCartItemListComponent extends CartItemListComponent implements OnInit, OnDestroy {
  constructor(
    activeCartService: ActiveCartFacade,
    selectiveCartService: SelectiveCartFacade,
    userIdService: UserIdService,
    multiCartService: MultiCartFacade,
    cd: ChangeDetectorRef,
    featureConfigService: FeatureConfigService
  ) {
    super(activeCartService, selectiveCartService, userIdService, multiCartService, cd);
  }

  ngOnInit(): void {
    // super.ngOnInit();
  }

  ngOnDestroy(): void {
    // super.ngOnDestroy();
  }
}
