import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// import { ModalRef, ModalService } from '@spartacus/storefront';
import { KnBrDownloadConfirmationDialogComponent } from '../kn-br-download-confirmation-dialog/kn-br-download-confirmation-dialog.component';
import { KnBrUpdateTemplateDialogComponent } from '../kn-br-update-template-dialog/kn-br-update-template-dialog.component';
import { KnBrUploadNewTemplateDialogComponent } from '../kn-br-upload-new-template-dialog/kn-br-upload-new-template-dialog.component';

@Component({
  selector: 'knbr-kn-br-nac-configurable',
  templateUrl: './kn-br-nac-configurable.component.html',
  styleUrls: ['./kn-br-nac-configurable.component.scss'],
})
export class KnBrNacConfigurableComponent implements OnInit {
  public fileUploadControl = new FileUploadControl()
    .setListVisibility(false)
    .setValidators([FileUploadValidators.filesLimit(1)]);
  documentTypes = '.csv';
  downloadTypeProductAttributes: false;
  downloadTypeImages: false;
  downloadOtherProductAssets: false;
  downloadAllTheProducts: false;
  uploadNewTemplateModalRef: NgbModalRef;
  updateTemplateModalRef: NgbModalRef;
  downloadConfirmationModalRef: NgbModalRef;

  nacConfigurbaleForm: UntypedFormGroup = this.fb.group({
    template: new UntypedFormControl({ value: '', disabled: false }),
  });

  constructor(protected fb: UntypedFormBuilder, protected modalService: NgbModal) {}

  ngOnInit(): void {}

  openUploadNewTemplateDialog() {
    this.uploadNewTemplateModalRef = this.modalService.open(KnBrUploadNewTemplateDialogComponent);
  }

  openUpdateTemplateDialog() {
    this.updateTemplateModalRef = this.modalService.open(KnBrUpdateTemplateDialogComponent);
  }

  openDownloadConfirmationDialog() {
    this.downloadConfirmationModalRef = this.modalService.open(KnBrDownloadConfirmationDialogComponent);
  }

  downloadProductAttributes() {}

  deleteTemplate() {}
}
