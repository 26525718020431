import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, ListNavigationModule, SpinnerModule } from '@spartacus/storefront';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { KnBrReturnOrderSearchResultComponent } from './kn-br-return-order-search-result.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { KnBrBrowsingModule } from 'src/app/common/kn-br-browsing/kn-br-browsing.module';

@NgModule({
  declarations: [KnBrReturnOrderSearchResultComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    ListNavigationModule,
    NgbTooltipModule,
    KnBrCustomPipesModule,
    KnBrBrowsingModule,
    ConfigModule.withConfig({
      cmsComponents: {
        OrderReturnRequestListComponent: {
          component: KnBrReturnOrderSearchResultComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrReturnOrderSearchResultModule {}
