import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn, tap } from 'rxjs/operators';

import {
  KnBrQuoteAddUpdateContext,
  KnBrQuoteLoadContext,
  KnBrQuoteRemoveContext,
  KnBrQuoteResetContext,
} from '../store/actions/kn-br-quote-context.actions';
import { getQuoteContext } from '../store/selecters/kn-br-quote-context.selector';
import { StateWithQuoteContext } from 'src/app/store/kn-br-quote-context/kn-br-quote-context.state';


@Injectable({
  providedIn: 'root',
})
export class KnBrQuoteContextService {
  constructor(private store: Store<StateWithQuoteContext>) {
    this.store.dispatch(new KnBrQuoteLoadContext());
  }

  get$: Observable<string> = this.store.pipe(
    select(getQuoteContext),
    observeOn(queueScheduler),
    tap((context) => {
      if (!context) {
        this.store.dispatch(new KnBrQuoteLoadContext());
      }
    })
  );
  set(context: string) {
    this.store.dispatch(new KnBrQuoteAddUpdateContext(context));
  }

  delete() {
    this.store.dispatch(new KnBrQuoteRemoveContext());
  }
  reset() {
    this.store.dispatch(new KnBrQuoteResetContext());
  }
}
