import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kn-br-quote-save',
  templateUrl: './kn-br-quote-save.component.html',
  styleUrls: ['./kn-br-quote-save.component.scss'],
})
export class KnBrQuoteSaveComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
