import { I18nModule } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrBrowsingComponent } from './kn-br-browsing.component';



@NgModule({
  declarations: [KnBrBrowsingComponent],
  imports: [
    CommonModule,
    I18nModule
  ],
  exports: [KnBrBrowsingComponent]
})
export class KnBrBrowsingModule { }
