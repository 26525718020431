<ng-container *ngIf="templateName && templateName != 'KnBrDraftOrderPageTemplate'">
  <div class="col-lg-12 p-0 float-right">
    <div class="float-right font-color-knbr-primary font-size font-text-600">
      <a (click)="downloadDraftOrderDetailPdf()" class="font-color-knbr-primary download-links ml-4">
        <i class="fas fa-download pr-1" aria-hidden="true"></i> {{ 'quoteCommon.label.downloadPDF' | cxTranslate }}
      </a>
      <a (click)="downloadDraftOrderDetailCSV()" class="font-color-knbr-primary download-links ml-4">
        <i class="fas fa-download pr-1" aria-hidden="true"></i> {{ 'quoteCommon.label.downloadCSV' | cxTranslate }}
      </a>
      <a (click)="downloadDraftOrderDetailImages()" class="font-color-knbr-primary download-links ml-4">
        <i class="fas fa-download pr-1" aria-hidden="true"></i> {{ 'quoteCommon.label.downloadImages' | cxTranslate }}
      </a>
      <a *ngIf="cartStatus === 'Draft'" (click)="deleteCart()" class="font-color-knbr-primary download-links ml-4">
        <i class="fas fa-times-circle pr-1" aria-hidden="true"></i> Delete Cart
      </a>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="templateName && templateName === 'KnBrDraftOrderPageTemplate'">
  <div
    class="col-lg-12 pl-0 pr-0 float-left d-flex justify-content-end mt-3 mb-0"
    *ngIf="productCodes && productCodes.length"
  >
    <button
      type="button"
      class="btn btn-outline-primary col-lg-2"
      *ngIf="!enableCheckboxFlag"
      (click)="enableCheckbox()"
    >
      {{ 'quoteCommon.label.downloadPDF' | cxTranslate }}<i class="fas fa-download ml-2" aria-hidden="true"></i>
    </button>
    <button
      type="button"
      class="btn btn-primary col-lg-2 mr-3"
      *ngIf="enableCheckboxFlag"
      (click)="downloadPdf()"
      [disabled]="!selectedProductsCodes || (selectedProductsCodes && selectedProductsCodes.length < 1)"
    >
      {{ 'quoteCommon.label.downloadPDF' | cxTranslate }}<i class="fas fa-download ml-2" aria-hidden="true"></i>
    </button>
    <button
      type="button"
      class="btn btn-outline-primary col-lg-2"
      *ngIf="enableCheckboxFlag"
      (click)="cancelDownload()"
    >
      {{ 'quoteCommon.label.cancel' | cxTranslate }}
    </button>
  </div>
</ng-container>
