<ng-container *ngIf="orderRefEntries && orderRefEntries.length">
  <a (click)="togglePartialOrder = !togglePartialOrder" class="text-dark">
    <i class="fas mr-1" [ngClass]="togglePartialOrder ? 'fa-chevron-down' : 'fa-chevron-right'"></i
    >{{ 'quotePartialItems.header.orderDetails' | cxTranslate }}</a
  >
  <div *ngIf="togglePartialOrder" class="customersearch-list-table float-left col-lg-12 p-0 mt-2">
    <div class="color-lighter-bg text-center border-top border-bottom float-left w-100 p-3 d-none d-sm-none d-lg-block">
      <div class="col-md-2 float-left font-text-600 pl-0 font-size">
        {{ 'quotePartialItems.header.order' | cxTranslate }}
      </div>
      <div class="col-md-1 float-left font-text-600 pl-0 font-size">
        {{ 'quotePartialItems.header.item' | cxTranslate }}
      </div>
      <div class="col-md-2 float-left font-text-600 pl-0 font-size">
        {{ 'quotePartialItems.header.product' | cxTranslate }}
      </div>
      <div class="col-md-2 float-left font-text-600 pl-0 font-size">
        {{ 'quotePartialItems.header.description' | cxTranslate }}
      </div>
      <div class="col-md-2 float-left font-text-600 pl-0 font-size">
        {{ 'quotePartialItems.header.quantity' | cxTranslate }}
      </div>
      <div class="col-md-1 float-left font-text-600 pl-0 font-size">
        {{ 'quotePartialItems.header.unit' | cxTranslate }}
      </div>
      <div class="col-md-2 float-left font-text-600 pl-0 font-size">
        {{ 'quotePartialItems.header.orderDate' | cxTranslate }}
      </div>
    </div>

    <!-- records start here -->
    <div
      class="color-lighter-bg table-content text-center border-bottom float-left w-100 p-3 font-weight-normal d-flex"
      *ngFor="let orderRef of orderRefEntries"
    >
      <div class="col-md-2 float-left title-wrap pl-0 color-font font-size color-font">
        <span class="res-titles"> {{ 'quotePartialItems.header.order' | cxTranslate }}</span>
        {{ orderRef.orderRefCode | removeLeadingZeros }}
      </div>

      <div class="col-md-1 float-left title-wrap pl-0 color-font font-size color-font">
        <span class="res-titles"> {{ 'quotePartialItems.header.item' | cxTranslate }}</span>
        {{ orderRef.entryNumber }}
      </div>

      <div class="col-md-2 float-left title-wrap pl-0 color-font font-size color-font">
        <span class="res-titles"> {{ 'quotePartialItems.header.product' | cxTranslate }}</span>
        {{ orderRef.product?.code }}
      </div>

      <div class="col-md-2 float-left title-wrap pl-0 color-font font-size color-font">
        <span class="res-titles"> {{ 'quotePartialItems.header.description' | cxTranslate }}</span>
        {{ orderRef?.product?.name }}
      </div>

      <div class="col-md-2 float-left title-wrap pl-0 color-font font-size color-font">
        <span class="res-titles"> {{ 'quotePartialItems.header.quantity' | cxTranslate }}</span>
        {{ orderRef.quantity }}
      </div>

      <div class="col-md-1 float-left title-wrap pl-0 color-font font-size color-font">
        <span class="res-titles"> {{ 'quotePartialItems.header.unit' | cxTranslate }}</span>
        {{ orderRef.uom }}
      </div>

      <div class="col-md-2 float-left title-wrap pl-0 color-font font-size color-font">
        <span class="res-titles"> {{ 'quotePartialItems.header.orderDate' | cxTranslate }}</span>
        {{ orderRef.creationTime }}
      </div>
    </div>
  </div>
</ng-container>
