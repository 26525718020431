import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, queueScheduler } from 'rxjs';
import { filter, observeOn } from 'rxjs/operators';

import { KnBrQuoteContextService } from './kn-br-quote-context.service';
import {
  KnBrQuoteCheckout,
  KnBrQuoteCopy,
  KnBrQuoteCreate,
  KnBrQuoteLoad,
  KnBrQuoteSearch,
  KnBrQuoteSearchReset,
  KnBrQuoteUpdate,
} from '../store/actions/kn-br-quotes.action';
import {
  getQuote,
  getQuoteCreateDisabled,
  getQuoteDetailStatuses,
  getQuotes,
  getQuotesDetailSpinner,
  getQuoteSearchCriteria,
  getQuoteSearchDisabled,
  getQuotesLoader,
  getQuotesPaginate,
  getQuotesSpinner,
} from '../store/selecters/kn-br-quotes.selector';

import { StateWithQuote } from 'src/app/store/kn-br-quotes/kn-br-quotes.state';
import { KnBrQuote } from 'src/feature-libs/kn-br-cart/quote/root/models/kn-br-quote.model';
import { Statuses } from 'src/feature-libs/kn-br-cart/draft-order/root/models/kn-br-draft-order.model';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuotesService {
  setSelectedProducts$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  constructor(private store: Store<StateWithQuote>, private knBrQuoteContextService: KnBrQuoteContextService) { }
  list$: Observable<KnBrQuote[]> = this.store.pipe(select(getQuotes), observeOn(queueScheduler));
  listStatuses$: Observable<Statuses[]> = this.store.pipe(select(getQuoteDetailStatuses), observeOn(queueScheduler));

  loading$: Observable<boolean> = this.store.pipe(select(getQuotesLoader), observeOn(queueScheduler));

  paginate$: Observable<any> = this.store.pipe(select(getQuotesPaginate), observeOn(queueScheduler));

  searchCriteria$: Observable<any> = this.store.pipe(select(getQuoteSearchCriteria), observeOn(queueScheduler));
  get$: Observable<any> = this.store.pipe(
    select(getQuote),
    observeOn(queueScheduler),
    filter((quote) => !!quote)
  );

  isSearchDisabled$: Observable<boolean> = this.store.pipe(select(getQuoteSearchDisabled), observeOn(queueScheduler));

  isCreateDisabled$: Observable<boolean> = this.store.pipe(select(getQuoteCreateDisabled), observeOn(queueScheduler));
  showSpinner$: Observable<boolean> = this.store.pipe(select(getQuotesSpinner), observeOn(queueScheduler));
  showDetailSpinner$: Observable<boolean> = this.store.pipe(select(getQuotesDetailSpinner), observeOn(queueScheduler));
  search(searchForm: KnBrQuote) {
    if ((searchForm && searchForm.currentPage === 0) || !searchForm) {
      this.reset();
    }
    this.store.dispatch(new KnBrQuoteSearch(searchForm));
  }

  reset() {
    this.store.dispatch(new KnBrQuoteSearchReset(null));
  }
  copy(payload: KnBrQuote) {
    this.store.dispatch(new KnBrQuoteCopy(payload));
  }

  create(payload: KnBrQuote) {
    this.store.dispatch(new KnBrQuoteCreate(payload));
  }
  update(payload: KnBrQuote) {
    this.store.dispatch(new KnBrQuoteUpdate(payload));
  }
  get(code) {
    this.knBrQuoteContextService.set(code);
    this.store.dispatch(new KnBrQuoteLoad({ code }));
  }

  setSelectedProducts(productCodes: string[]) {
    this.setSelectedProducts$.next(productCodes);
  }

  loadSelectedProducts$(): Observable<string[]> {
    return this.setSelectedProducts$.asObservable();
  }

  acceptAndCheckout(payload: KnBrQuote) {
    this.store.dispatch(new KnBrQuoteCheckout(payload));
  }
}
