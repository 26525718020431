import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kn-br-message-dialog',
  templateUrl: './kn-br-message-dialog.component.html',
  styleUrls: ['./kn-br-message-dialog.component.scss'],
})
export class KnBrMessageDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() innerHtml: string;
  constructor(private activeModal: NgbActiveModal) {}
  ngOnInit() {}

  public accept() {
    this.activeModal.close(true);
  }
  public dismiss() {
    this.activeModal.dismiss();
  }
}
