import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { KnBrQuickLinksComponent } from './kn-br-quick-links.component';

@NgModule({
  imports: [
    CommonModule,
    GenericLinkModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuickLinksComponent: {
          component: KnBrQuickLinksComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [KnBrQuickLinksComponent],
})
export class KnBrQuickLinksModule {}
