import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class UrlPatternService {
  private readonly urlPattern =
    /https:\/\/knackbrava-dev\.knackintradev\.com\/occ\/v2\/brava-customer\/knbr\/customer\/\d+\/carts\/\d+\?.*fields=DEFAULT.*/;
  private readonly statusUrlPattern =
    /https:\/\/knackbrava-dev\.knackintradev\.com\/occ\/v2\/brava-customer\/knbr\/customer\/\d+\/carts\/\d+\/statuses/;
  private readonly statusUrlPatternnull =
    /https:\/\/knackbrava-dev\.knackintradev\.com\/occ\/v2\/brava-customer\/knbr\/customer\/\d+\/carts\/null\/statuses/;
  isBlockedUrl(url: string): boolean {
    return this.urlPattern.test(url);
  }
  isBlockedStatusUrl(urlstring: string): boolean {
    return this.statusUrlPattern.test(urlstring);
  }
  isBlockedStatusUrlNull(urlstring: string): boolean {
    return this.statusUrlPatternnull.test(urlstring);
  }
}
