import { Action } from '@ngrx/store';

export enum KnBrOrderHistoryActionTypes {
  KnBrOrderHistorySearchAction = '[OrderHistory] Search',
  KnBrOrderHistorySearchSuccessAction = '[OrderHistory] Search Success',
  KnBrOrderHistoryQuickSearchSuccessAction = '[OrderHistory] Quick Search Success',
  KnBrOrderHistorySearchFailureAction = '[OrderHistory] Search Failure',
  KnBrOrderHistorySearchResetAction = '[OrderHistory] Search Reset',
  KnBrCreateReturnOrderAction = '[OrderReturn] Create',
  KnBrCreateReturnOrderSuccessAction = '[OrderReturn] Create Success',
  KnBrCreateReturnOrderFailureAction = '[OrderReturn] Create Failure',
  KnBrOrderReOrderAction = '[Order] ReOrder',
  KnBrOrderReOrderCopySuccessAction = '[Order] ReOrder Success',
  KnBrOrderReOrderCopyFailureAction = '[Order] ReOrder Failure',
  KnBrOrderCancelAction = '[OrderCancel] Cancel',
  KnBrOrderCancelSuccessAction = '[OrderCancel] Cancel Success',
  KnBrOrderCancelFailureAction = '[OrderCancel] Cancel Failure',
  KnBrOrderFormsProductsSearchAction = '[OrderForms] Products Search',
  KnBrOrderFormsProductsSearchSuccessAction = '[OrderForms] Products Success',
  KnBrOrderFormsProductsSearchFailureAction = '[OrderForms] Products Failure',
  KnBrOrderFormsProductsResetAction = '[OrderForms] Reset data',
  KnBrEditOrderAction = '[EditOrder] ',
  KnBrEditOrderSuccessAction = '[EditOrder]  Success',
  KnBrEditOrderFailureAction = '[EditOrder]  Failure',
}

/**
 * Action to Search Order History
 */
export class KnBrOrderHistorySearch implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Order History Search
 */
export class KnBrOrderHistorySearchSuccess implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure Order History Search
 */
export class KnBrOrderHistorySearchFailure implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on successful Order History Search
 */
export class KnBrOrderHistoryQuickSearchSuccess implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderHistoryQuickSearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Order History Search
 */
export class KnBrOrderHistorySearchReset implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchResetAction;
  constructor(public payload: any) {}
}

/**
 * Action to Create return order
 */
export class KnBrCreateReturnOrder implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderAction;
  constructor(public payload: any) {}
}
/**
 * Action dispatched on Order Copy
 */
export class KnBrOrderReOrder implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderReOrderAction;
  constructor(public payload: any) {}
}

/**
 * Action create return order successful
 */
export class KnBrCreateReturnOrderSuccess implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderSuccessAction;
  constructor(public payload: any) {}
}
/**
 * Action dispatched on Order Copy Success
 */
export class KnBrOrderReOrderSuccess implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderReOrderCopySuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on   create return order failure
 */
export class KnBrCreateReturnOrderFailure implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderFailureAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Copy Failure
 */
export class KnBrOrderReOrderFailure implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderReOrderCopyFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action to Search Order History
 */
export class KnBrOrderCancel implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderCancelAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Order History Search
 */
export class KnBrOrderCancelSuccess implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderCancelSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure Order History Search
 */
export class KnBrOrderCancelFailure implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderCancelFailureAction;
  constructor(public payload: any) {}
}

/**
 * Action to Order Forms Products search
 */
export class KnBrOrderFormsProductsSearch implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Order Forms Products search
 */
export class KnBrOrderFormsProductsSearchSuccess implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure Order Forms Products search
 */
export class KnBrOrderFormsProductsSearchFailure implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchFailureAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure Order Forms Products search
 */
export class KnBrOrderFormsProductsReset implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsResetAction;
  constructor(public payload: any) {}
}

/**
 * Action to Edit Order
 */
export class KnBrEditOrder implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrEditOrderAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Edit Order
 */
export class KnBrEditOrderSuccess implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrEditOrderSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on failure Edit Order
 */
export class KnBrEditOrderFailure implements Action {
  readonly type = KnBrOrderHistoryActionTypes.KnBrEditOrderFailureAction;
  constructor(public payload: any) {}
}

export type KnBrOrderHistoryActions =
  | KnBrOrderHistorySearch
  | KnBrOrderHistorySearchSuccess
  | KnBrOrderHistoryQuickSearchSuccess
  | KnBrOrderHistorySearchFailure
  | KnBrOrderHistorySearchReset
  | KnBrCreateReturnOrder
  | KnBrCreateReturnOrderSuccess
  | KnBrCreateReturnOrderFailure
  | KnBrOrderReOrder
  | KnBrOrderReOrderSuccess
  | KnBrOrderReOrderFailure
  | KnBrOrderCancel
  | KnBrOrderCancelSuccess
  | KnBrOrderCancelFailure
  | KnBrOrderFormsProductsSearch
  | KnBrOrderFormsProductsSearchSuccess
  | KnBrOrderFormsProductsSearchFailure
  | KnBrEditOrder
  | KnBrEditOrderSuccess
  | KnBrEditOrderFailure
  | KnBrOrderFormsProductsReset;
