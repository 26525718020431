import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ModalService } from '@spartacus/storefront';

@Component({
  selector: 'kn-br-quote-reject-comments',
  templateUrl: './kn-br-quote-reject-comments.component.html',
})
export class KnBrQuoteRejectCommentsComponent implements OnInit {
  comment: string;
  constructor(protected modalService: NgbModal) {}

  ngOnInit(): void {}

  saveComment() {
    this.modalService.dismissAll(this.comment);
  }
  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }
}
