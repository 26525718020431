import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

import { KnBrDraftOrderAttachmentLinkComponent } from './kn-br-draft-order-attachment-link.component';

@NgModule({
  declarations: [KnBrDraftOrderAttachmentLinkComponent],
  imports: [
    CommonModule,
    BrowserModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    BrowserAnimationsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderAttachmentLinkComponent: {
          component: KnBrDraftOrderAttachmentLinkComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrDraftOrderAttachmentLinkModule {}
