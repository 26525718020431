<form (ngSubmit)="onSubmit()" [formGroup]="updateProfileForm">
  <div class="form-group row">
    <div class="col-md-12">
      <label>
        <span class="label-content">{{ 'updateProfileForm.title' | cxTranslate }}</span>
        <select formControlName="titleCode" class="form-control">
          <option value="">{{ 'updateProfileForm.none' | cxTranslate }}</option>
          <option *ngFor="let title of titles" [value]="title.code">
            {{ title.name }}
          </option>
        </select>
      </label>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-12">
      <label>
        <span class="label-content">{{ 'updateProfileForm.firstName.label' | cxTranslate }}</span>
        <input
          type="text"
          class="form-control"
          name="firstName"
          placeholder="{{ 'updateProfileForm.firstName.placeholder' | cxTranslate }}"
          formControlName="firstName"
        />
        <cx-form-errors [control]="updateProfileForm.get('firstName')"></cx-form-errors>
      </label>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-12">
      <label>
        <span class="label-content">{{ 'updateProfileForm.lastName.label' | cxTranslate }}</span>
        <input
          type="text"
          class="form-control"
          name="lastName"
          placeholder="{{ 'updateProfileForm.lastName.placeholder' | cxTranslate }}"
          formControlName="lastName"
        />
        <cx-form-errors [control]="updateProfileForm.get('lastName')"></cx-form-errors>
      </label>
    </div>
  </div>

  <!-- <div class="form-group row">
    <div class="col-md-12">
      <label>
        <span class="label-content">{{
          'updateProfileForm.customerId' | cxTranslate
        }}</span>
        <input
          type="text"
          class="form-control"
          name="customerId"
          formControlName="customerId"
          id="customerId"
          readonly
        />
        <cx-form-errors
          [control]="updateProfileForm.get('lastName')"
        ></cx-form-errors>
      </label>
    </div>
  </div> -->

  <div class="form-group row">
    <div class="col-lg-6 col-md-12">
      <button class="btn btn-block btn-secondary" type="button" (click)="onCancel()">
        {{ 'common.cancel' | cxTranslate }}
      </button>
    </div>
    <div class="col-lg-6 col-md-12">
      <button class="btn btn-block btn-primary" type="submit">
        {{ 'common.save' | cxTranslate }}
      </button>
    </div>
  </div>
</form>
