import { Action } from '@ngrx/store';

export enum KnBrQuotesActionTypes {
  KnBrQuoteSearchAction = '[Quote] Search',
  KnBrQuoteSearchSuccessAction = '[Quote] Search Success',
  KnBrQuoteSearchFailureAction = '[Quote] Search Failure',
  KnBrQuoteSearchResetAction = '[Quote] Search Reset',
  KnBrQuoteCopyAction = '[Quote] Copy',
  KnBrQuoteCopySuccessAction = '[Quote] Copy Success',
  KnBrQuoteCopyFailureAction = '[Quote] Copy Failure',
  KnBrQuoteUpdateAction = '[Quote] Update',
  KnBrQuoteUpdateSuccessAction = '[Quote] Update Success',
  KnBrQuoteUpdateFailureAction = '[Quote] Update Failure',
  KnBrQuoteCreateAction = '[Quote] Create',
  KnBrQuoteCreateSuccessAction = '[Quote] Create Success',
  KnBrQuoteCreateFailureAction = '[Quote] Create Failure',
  KnBrQuoteLoadAction = '[Quote] Load Quote',
  KnBrQuoteLoadFailureAction = '[Quote] Load Quote Fail',
  KnBrQuoteLoadSuccessAction = '[Quote] Load Quote Success',
  KnBrQuoteStatusesLoadAction = '[Quote] Load Quote Statuses',
  KnBrQuoteLoadStatusesFailureAction = '[Quote] Load Quote Statuses Fail',
  KnBrQuoteLoadStatusesSuccessAction = '[Quote] Load Quote Statuses Success',
  KnBrQuoteCheckoutAction = '[Quote] Checkout',
  KnBrQuoteCheckoutSuccessAction = '[Quote] Checkout Success',
  KnBrQuoteCheckoutFailureAction = '[Quote] Checkout Failure',
}

/**
 * Action to Search Quotes
 */
export class KnBrQuoteSearch implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteSearchAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Quotes Search
 */
export class KnBrQuoteSearchSuccess implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteSearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Quotes Search
 */
export class KnBrQuoteSearchReset implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteSearchResetAction;
  constructor(public payload: any) {}
}
/**
 * Action dispatched on failure Quotes Search
 */
export class KnBrQuoteSearchFailure implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteSearchFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on Quote Copy
 */
export class KnBrQuoteCopy implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCopyAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Copy Success
 */
export class KnBrQuoteCopySuccess implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCopySuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Copy Failure
 */
export class KnBrQuoteCopyFailure implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCopyFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on Quote Update
 */
export class KnBrQuoteUpdate implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteUpdateAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Update Success
 */
export class KnBrQuoteUpdateSuccess implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteUpdateSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Update Failure
 */
export class KnBrQuoteUpdateFailure implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteUpdateFailureAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Create
 */
export class KnBrQuoteCreate implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCreateAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Create Success
 */
export class KnBrQuoteCreateSuccess implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCreateSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Create Failure
 */
export class KnBrQuoteCreateFailure implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCreateFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on Quote Load
 */
export class KnBrQuoteLoad implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteLoadAction;
  constructor(public payload: any, public refreshContext: boolean = true) {}
}

/**
 * Action dispatched on Quote Load Success
 */
export class KnBrQuoteLoadSuccess implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteLoadSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Load Failure
 */
export class KnBrQuoteLoadFailure implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteLoadFailureAction;
  constructor(public payload: Error) {}
}
export class KnBrQuoteStatusesLoad implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteStatusesLoadAction;
  constructor(public payload: any) {}
}
export class KnBrQuoteLoadStatusesSuccess implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteLoadStatusesSuccessAction;
  constructor(public payload: any) {}
}
export class KnBrQuoteLoadStatusesFailure implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteLoadStatusesFailureAction;
  constructor(public payload: any) {}
}
/**
 * Action dispatched on Quote Checkout
 */
export class KnBrQuoteCheckout implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCheckoutAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Checkout Success
 */
export class KnBrQuoteCheckoutSuccess implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCheckoutSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Quote Checkout Failure
 */
export class KnBrQuoteCheckoutFailure implements Action {
  readonly type = KnBrQuotesActionTypes.KnBrQuoteCheckoutFailureAction;
  constructor(public payload: Error) {}
}
export type KnBrQuotesSearchActions =
  | KnBrQuoteSearch
  | KnBrQuoteSearchSuccess
  | KnBrQuoteSearchFailure
  | KnBrQuoteSearchReset
  | KnBrQuoteCopy
  | KnBrQuoteCopySuccess
  | KnBrQuoteCopyFailure
  | KnBrQuoteUpdate
  | KnBrQuoteUpdateSuccess
  | KnBrQuoteUpdateFailure
  | KnBrQuoteCreate
  | KnBrQuoteCreateSuccess
  | KnBrQuoteCreateFailure
  | KnBrQuoteLoad
  | KnBrQuoteLoadSuccess
  | KnBrQuoteLoadFailure
  | KnBrQuoteStatusesLoad
  | KnBrQuoteLoadStatusesSuccess
  | KnBrQuoteLoadStatusesFailure
  | KnBrQuoteCheckout
  | KnBrQuoteCheckoutSuccess
  | KnBrQuoteCheckoutFailure;
