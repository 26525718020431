<ng-container *ngIf="!(isLoading$ | async); else loading">
  <div class="col-md-12 p-0 float-left knbr-user-register">
    <div class="col-md-7 pt-4 pl-4 float-left">
      <h3 class="mb-1 color-font">{{ 'register.pageHeader1' | cxTranslate }}</h3>
      <h5 class="text-muted mb-3">{{ 'register.pageHeader2' | cxTranslate }}</h5>
      <form (ngSubmit)="submitForm()" [formGroup]="registerForm">
        <div class="float-left col-md-12 p-0 mt-2">
          <label class="mb-0 text-muted">{{ 'register.firstName.label' | cxTranslate }}</label>
          <input class="form-control" type="text" name="firstname"
            placeholder="{{ 'register.firstName.placeholder' | cxTranslate }}" formControlName="firstName" />
          <cx-form-errors [control]="registerForm.get('firstName')"></cx-form-errors>
        </div>

        <div class="float-left col-md-12 p-0 mt-2">
          <label class="mb-0 text-muted">{{ 'register.lastName.label' | cxTranslate }}</label>
          <input class="form-control" type="text" name="lastname"
            placeholder="{{ 'register.lastName.placeholder' | cxTranslate }}" formControlName="lastName" />
          <cx-form-errors [control]="registerForm.get('lastName')"></cx-form-errors>
        </div>

        <div class="float-left col-md-12 p-0 mt-2">
          <label class="mb-0 text-muted">{{ 'register.emailAddress.label' | cxTranslate }}</label>
          <input class="form-control" type="email" name="email"
            placeholder="{{ 'register.emailAddress.placeholder' | cxTranslate }}" formControlName="email" />
          <cx-form-errors [control]="registerForm.get('email')"></cx-form-errors>
        </div>

        <div class="float-left col-md-12 p-0 mt-2">
          <label class="mb-0 text-muted">{{ 'register.confEmailAddress.label' | cxTranslate }}</label>
          <input class="form-control" type="email" name="emailconf"
            placeholder="{{ 'register.confEmailAddress.placeholder' | cxTranslate }}" formControlName="emailconf" />
          <cx-form-errors [control]="registerForm.get('emailconf')"></cx-form-errors>
        </div>

        <div class="float-left col-md-12 p-0 mt-2">
          <label class="mb-0 text-muted">{{ 'register.accountNumber.label' | cxTranslate }}</label>
          <input class="form-control" type="text" name="unit"
            placeholder="{{ 'register.accountNumber.placeholder' | cxTranslate }}" formControlName="unit" />
          <cx-form-errors [control]="registerForm.get('unit')"></cx-form-errors>
        </div>

        <div class="float-left col-md-12 p-0 mt-2">
          <label class="mb-0 text-muted">{{ 'register.jobFunction.label' | cxTranslate }}</label>
          <ng-select [items]="jobFunctionList$ | async" bindLabel="Text" bindValue="Key"
            [placeholder]="'register.jobFunction.placeholder' | cxTranslate" formControlName="jobFunction">
          </ng-select>
        </div>

        <div class="float-left col-md-12 p-0 mt-2">
          <label class="mb-0 text-muted">{{ 'register.phoneNumber.label' | cxTranslate }}</label>
          <input class="form-control" type="text" name="contactnumber"
            placeholder="{{ 'register.phoneNumber.placeholder' | cxTranslate }}" formControlName="contactNumber"
            maxlength="10" />
          <cx-form-errors [control]="registerForm.get('contactNumber')"></cx-form-errors>
        </div>
        <div class="float-left col-md-12 p-0 mt-2">
          <div class="form-check-consent form-check-inline mb-2" id="">
            <input class="form-check-input" type="checkbox" id="clickwrapContainer"
              formControlName="acceptanceCheckbox" />
            <label class="form-check-label mb-2" for="clickwrapContainer">
              <span class="text-danger mandatory-font-size ml-1">*</span>
              I understand and agree to <a href="{{sloanprivacypolicy}}" data-ckey="sloan-terms-of-use" target="_blank"
                rel="noopener" class="ps-contract-link">Privacy Policy</a> and <a href="{{sloantermsofuse}}"
                data-ckey="sloan-terms-of-use" target="_blank" rel="noopener" class="ps-contract-link">Terms of Use</a>
            </label>
          </div>
        </div>
        <div class="float-left col-md-12 p-0 mt-2">
          <re-captcha id="recaptcha" name="recaptcha" formControlName="token"></re-captcha>
          <cx-form-errors [control]="registerForm.get('token')"></cx-form-errors>
        </div>

        <div class="float-left col-md-12 p-0 mt-4 mb-3 d-flex align-items-center">
          <div class="col-md-6 p-0 float-left">
            <a class="btn-link" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{ 'register.signIn' | cxTranslate }}</a>
          </div>
          <div class="col-md-6 p-0 float-right">
            <button type="submit" [disabled]="!this.registerForm.value.acceptanceCheckbox"
              class="btn btn-primary col-md-12">
              {{ 'register.register' | cxTranslate }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-5 pt-4 pl-4 pr-4 float-left">
      <cx-page-slot position="SideContent"></cx-page-slot>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner"><cx-spinner></cx-spinner></div>
</ng-template>
