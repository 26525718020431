<div class="modal-header d-flex align-items-center">
  <h4 class="modal-title">{{ 'label.comments' | cxTranslate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body float-left w-100 p-0">
  <div class="">
    <div class="col-md-12 float-left customersearch-forms login-forms pt-0 pl-3 pr-3 m-0">
      <div class="form-group head-notes mt-2 col-lg-12 pl-0 float-left mb-0 p-0">
        <textarea
          class="form-control w-100"
          id="exampleFormControlTextarea1"
          rows="3"
          [placeholder]="'label.comments' | cxTranslate"
          [(ngModel)]="comment"
        ></textarea>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container>
      <button
        type="button"
        (click)="saveComment()"
        class="btn color-knbr-primary color-white hover-link"
        [disabled]="!comment?.trim().length"
      >
        {{ 'button.save' | cxTranslate }}
      </button>
    </ng-container>

    <button type="button" class="btn color-knbr-secondary color-white hover-link" (click)="dismissModal()">
      {{ 'button.close' | cxTranslate }}
    </button>
  </div>
</div>
