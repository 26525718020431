import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'removeBaseSiteId',
})
export class KnBrRemoveBaseSiteIdPipe implements PipeTransform {
  transform(input: string): string {
    return input ? input.toLowerCase().replace((environment.BASE_SITE[0] + '|').toLowerCase(), '') : input;
  }
}
