import { NgModule } from '@angular/core';
import { PageMetaResolver } from '@spartacus/core';
import { OrganizationPageMetaResolver } from '@spartacus/organization/administration/core';

import { KnBrCheckoutPageMetaResolver } from './kn-br-checkout-page-meta.resolver';
import { KnBrContentPageMetaResolver } from './kn-br-content-page-meta.resolver';
import { KnBrSearchPageMetaResolver } from './kn-br-product-search-page-meta.resolver';
import { KnBrUsersPageMetaResolver } from './kn-br-users-page-meta.resolver';

@NgModule({
  providers: [
    {
      provide: PageMetaResolver,
      useExisting: KnBrContentPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: KnBrSearchPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: KnBrCheckoutPageMetaResolver,
      multi: true,
    },
    {
      provide: OrganizationPageMetaResolver,
      useClass: KnBrUsersPageMetaResolver,
      multi: true,
    },
  ],
})
export class KnBrMetaResolverModule {}
