import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ListNavigationModule, SpinnerModule } from '@spartacus/storefront';
import { KnBrOrderHistorySearchResultComponent } from './kn-br-order-history-search-result.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  declarations: [KnBrOrderHistorySearchResultComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    NgbTooltipModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountOrderHistoryComponent: {
          component: KnBrOrderHistorySearchResultComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrOrderHistorySearchResultModule {}
