<div class="modal-header col-md-12 float-left p-3">
  <h4 class="modal-title font-size-18 color-font font-text-bold">
    {{ 'updateTemplateDialog.heading' | cxTranslate }}
  </h4>
  <button type="button" class="close pb-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body col-md-12 float-left p-3 border-bottom">
  <div class="col-md-12 p-0 float-left border-bottom">
    <div class="col-md-12 float-left pl-0">
      <label class="label-content">
        {{ 'updateTemplateDialog.labels.downloadLabel' | cxTranslate }}
      </label>
    </div>
    <div class="col-md-12 p-0 float-left mb-3 mt-2">
      <button type="button" class="btn btn-secondary col-md-5" (click)="download()">
        {{ 'updateTemplateDialog.buttons.download' | cxTranslate }}
      </button>
    </div>
  </div>
  <div class="col-md-12 p-0 float-left mt-2">
    <div class="col-md-12 float-left p-0 mb-2">
      <label class="label-content"> {{ 'updateTemplateDialog.labels.selectFileLabel' | cxTranslate }}</label>
    </div>
    <div class="col-md-12 p-0 float-left mb-3 uploadTemplate">
      <file-upload class="d-inline-flex" [accept]="documentTypes" simple [control]="fileUploadControl">
        <ng-template let-control="control" #button>
          <a class="btn btn-secondary"> {{ 'updateTemplateDialog.labels.chooseFile' | cxTranslate }}</a>
        </ng-template>
      </file-upload>
      <ng-container *ngIf="fileUploadControl.value.length == 0">
        <span class="ml-3 font-italic"> {{ 'updateTemplateDialog.labels.noFileChosen' | cxTranslate }}</span>
      </ng-container>
      <ng-container *ngIf="fileUploadControl.value.length > 0">
        <ng-container *ngFor="let file of fileUploadControl.value">
          <span class="ml-3 font-italic">{{ file.name }}</span>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-md-12 float-left p-0">
      <button type="button" class="btn btn-primary col-md-5" (click)="update()">
        {{ 'updateTemplateDialog.buttons.update' | cxTranslate }}
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary col-md-3" (click)="dismiss()">
    {{ 'updateTemplateDialog.buttons.cancel' | cxTranslate }}
  </button>
</div>
