<div class="modal-header d-flex align-items-center p-3">
  <h4 class="modal-title font-size-18 font-text-bold">{{ title | cxTranslate }}</h4>
  <button type="button" class="close pb-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ message | cxTranslate }}
</div>
<div class="modal-footer">
  <button type="button" class="btn color-knbr-secondary color-white" (click)="accept()">
    {{ btnOkText | cxTranslate }}
  </button>
  <button type="button" class="btn color-knbr-secondary color-white" (click)="decline()">
    {{ btnCancelText | cxTranslate }}
  </button>
  <button type="button" class="btn color-knbr-secondary color-white" (click)="dismiss()">
    {{ 'button.cancel' | cxTranslate }}
  </button>
</div>
