import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { OCC_USER_ID_ANONYMOUS } from '@spartacus/core';
import { UserIdService, OCC_USER_ID_CURRENT } from '@spartacus/core';
import { Observable } from 'rxjs';

import { KnBrCustomerContextService } from '../../store/kn-br-customer-context/kn-br-customer-context.service';

@Injectable({ providedIn: 'root' })
export class CustomerIdMapHttpInterceptor implements HttpInterceptor {
  customerContext: string;
  isLoggedInASM = false;
  userId;
  constructor(
    private customerContextService: KnBrCustomerContextService,
    protected userIdService: UserIdService,
    protected csAgentAuthService: CsAgentAuthService
  ) {
    this.customerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerContext = response;
    });
    this.csAgentAuthService.isCustomerSupportAgentLoggedIn().subscribe((res) => {
      this.isLoggedInASM = res;
    });
    this.userIdService.getUserId().subscribe((userId) => {
      this.userId = userId;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.customerContext || this.userId) {
      request = request.clone({
        setParams: {
          customerId:
            this.isLoggedInASM || this.isNotAnonoymusOrCurrent(this.userId) ? this.userId : this.customerContext,
        },
      });
    }
    return next.handle(request);
  }

  isNotAnonoymusOrCurrent(userId) {
    return !(userId === OCC_USER_ID_CURRENT || userId === OCC_USER_ID_ANONYMOUS);
  }
}
