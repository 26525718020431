import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SloanPolicyReacceptanceComponent } from './sloan-policy-reacceptance.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [SloanPolicyReacceptanceComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SloanPolicyReacceptanceComponent: {
          component: SloanPolicyReacceptanceComponent,

        },
      },
    } as CmsConfig),
  ],
  exports: [SloanPolicyReacceptanceComponent],
})
export class SloanPolicyReacceptanceModule { }
