import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { KnBrCustomerTitleComponent } from './kn-br-customer-title.component';

@NgModule({
    declarations: [KnBrCustomerTitleComponent],
    imports: [
        CommonModule,
        KnBrCustomPipesModule,
        ConfigModule.withConfig({
            cmsComponents: {
                KnBrCustomerTitleComponent: {
                    component: KnBrCustomerTitleComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrCustomerTitleComponent]
})
export class KnBrCustomersTitleModule {}
