import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { KnBrBackButtonComponent } from './kn-br-back-button.component';

@NgModule({
    declarations: [KnBrBackButtonComponent],
    imports: [
        CommonModule,
        ConfigModule.withConfig({
            cmsComponents: {
                KnBrBackButtonComponent: {
                    component: KnBrBackButtonComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrBackButtonComponent]
})
export class KnBrBackButtonModule {}
