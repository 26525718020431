import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KnBrProductSelectService {
  setSelectedProducts$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  constructor() {}

  setSelectedProducts(productCodes: string[]) {
    this.setSelectedProducts$.next(productCodes);
  }

  loadSelectedProducts$(): Observable<string[]> {
    return this.setSelectedProducts$.asObservable();
  }
}
