import { Action } from '@ngrx/store';

export enum KnBrDraftOrdersActionTypes {
  KnBrDraftOrderSearchAction = '[DraftOrder] Search',
  KnBrDraftOrderSearchSuccessAction = '[DraftOrder] Search Success',
  KnBrDraftOrderSearchFailureAction = '[DraftOrder] Search Failure',
  KnBrDraftOrderSearchResetAction = '[DraftOrder] Search Reset',
  KnBrDraftOrderCopyAction = '[DraftOrder] Copy',
  KnBrDraftOrderCopySuccessAction = '[DraftOrder] Copy Success',
  KnBrDraftOrderCopyFailureAction = '[DraftOrder] Copy Failure',
  KnBrDraftOrderUpdateAction = '[DraftOrder] Update',
  KnBrDraftOrderPatchAction = '[DraftOrder] Patch',
  KnBrDraftOrderUpdateSuccessAction = '[DraftOrder] Update Success',
  KnBrDraftOrderUpdateFailureAction = '[DraftOrder] Update Failure',
  KnBrDraftOrderCreateAction = '[DraftOrder] Create',
  KnBrDraftOrderCreateSuccessAction = '[DraftOrder] Create Success',
  KnBrDraftOrderCreateFailureAction = '[DraftOrder] Create Failure',
  KnBrDraftOrderEntryUpdateAction = '[DraftOrder-Entry] Update',
  KnBrDraftOrderEntryUpdateSuccessAction = '[DraftOrder-Entry] Update Success',
  KnBrDraftOrderEntryUpdateFailureAction = '[DraftOrder-Entry] Update Failure',
  KnBrDraftOrderChekoutValidationAction = '[DraftOrder] Checkout Validation',
  KnBrDraftOrderStatusesLoadAction = '[DraftOrder] Load DraftOrder Statuses',
  KnBrDraftOrderLoadStatusesFailureAction = '[DraftOrder] Load DraftOrder Statuses Fail',
  KnBrDraftOrderLoadStatusesSuccessAction = '[DraftOrder] Load DraftOrder Statuses Success',
  KnBrDraftOrderQuickEntryAction = '[DraftOrder] Add Products QuickEntry',
  KnBrDraftOrderQuickEntryFailureAction = '[DraftOrder] Add Products QuickEntry  Fail',
  KnBrDraftOrdeQuickEntrySuccessAction = '[DraftOrder] Add Products QuickEntry Success',
  KnBrQuickOrderResetFormAction = '[QuickOrder] Reset Form',
  KnBrDraftOrderWarningMsgAction = '[DraftOrder] Warning Message',
  KnBrDraftOrderDeleteAction = '[DraftOrder] Delete',
  KnBrDraftOrderDeleteSuccessAction = '[DraftOrder] Delete Success',
  KnBrDraftOrderDeleteFailureAction = '[DraftOrder] Delete Failure',
}

/**
 * Action to Search Draft Orders
 */
export class KnBrDraftOrderSearch implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Draft Orders Search
 */
export class KnBrDraftOrderSearchSuccess implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on successful Draft Orders Search
 */
export class KnBrDraftOrderSearchReset implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchResetAction;
  constructor(public payload: any) {}
}
/**
 * Action dispatched on failure Draft Orders Search
 */
export class KnBrDraftOrderSearchFailure implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on Order Copy
 */
export class KnBrDraftOrderCopy implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderCopyAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Copy Success
 */
export class KnBrDraftOrderCopySuccess implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderCopySuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Copy Failure
 */
export class KnBrDraftOrderCopyFailure implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderCopyFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on Order Update
 */
export class KnBrDraftOrderUpdate implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Patch
 */
export class KnBrDraftOrderPatch implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderPatchAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Update Success
 */
export class KnBrDraftOrderUpdateSuccess implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Update Failure
 */
export class KnBrDraftOrderUpdateFailure implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateFailureAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Create
 */
export class KnBrDraftOrderCreate implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Create Success
 */
export class KnBrDraftOrderCreateSuccess implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Create Failure
 */
export class KnBrDraftOrderCreateFailure implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateFailureAction;
  constructor(public payload: Error) {}
}

/**
 * Action dispatched on Order entry update
 */
export class KnBrDraftOrderEntryUpdate implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderEntryUpdateAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Create
 */
export class KnBrDraftOrderEntryUpdateSuccess implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderEntryUpdateSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Create
 */
export class KnBrDraftOrderEntryUpdateFailure implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderEntryUpdateFailureAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on checkout order
 */
export class KnBrDraftOrderChekoutValidation implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderChekoutValidationAction;
  constructor(public payload: any) {}
}
export class KnBDraftOrderStatusesLoad implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderStatusesLoadAction;
}
export class KnBrDraftOrderLoadStatusesSuccess implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderLoadStatusesSuccessAction;
  constructor(public payload: any) {}
}
export class KnBrDraftOrderLoadStatusesFailure implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderLoadStatusesFailureAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on add products quickentry
 */
export class KnBrDraftOrderQuickEntry implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderQuickEntryAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched onadd products quickentry Success
 */
export class KnBrDraftOrderQuickEntrySuccess implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrdeQuickEntrySuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on add products quickentry Failure
 */
export class KnBrDraftOrderQuickEntryFailure implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderQuickEntryFailureAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on reset form on quickorder or quick entry
 */
export class KnBrQuickOrderResetForm implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrQuickOrderResetFormAction;
  constructor(public payload: boolean) {}
}

/**
 * Action to Reeset Warning Message
 */
export class KnBrDraftOrderWarningMessage implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderWarningMsgAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Delete
 */
export class KnBrDraftOrderDelete implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Delete Success
 */
export class KnBrDraftOrderDeleteSuccess implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteSuccessAction;
  constructor(public payload: any) {}
}

/**
 * Action dispatched on Order Delete Failure
 */
export class KnBrDraftOrderDeleteFailure implements Action {
  readonly type = KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteFailureAction;
  constructor(public payload: any) {}
}

export type KnBrDraftOrdersSearchActions =
  | KnBrDraftOrderSearch
  | KnBrDraftOrderSearchSuccess
  | KnBrDraftOrderSearchFailure
  | KnBrDraftOrderSearchReset
  | KnBrDraftOrderCopy
  | KnBrDraftOrderCopySuccess
  | KnBrDraftOrderCopyFailure
  | KnBrDraftOrderUpdate
  | KnBrDraftOrderPatch
  | KnBrDraftOrderChekoutValidation
  | KnBrDraftOrderUpdateSuccess
  | KnBrDraftOrderUpdateFailure
  | KnBrDraftOrderCreate
  | KnBrDraftOrderCreateSuccess
  | KnBrDraftOrderCreateFailure
  | KnBrDraftOrderEntryUpdate
  | KnBrDraftOrderEntryUpdateSuccess
  | KnBrDraftOrderEntryUpdateFailure
  | KnBDraftOrderStatusesLoad
  | KnBrDraftOrderLoadStatusesSuccess
  | KnBrDraftOrderLoadStatusesFailure
  | KnBrDraftOrderQuickEntry
  | KnBrDraftOrderQuickEntrySuccess
  | KnBrDraftOrderQuickEntryFailure
  | KnBrQuickOrderResetForm
  | KnBrDraftOrderWarningMessage
  | KnBrDraftOrderDelete
  | KnBrDraftOrderDeleteSuccess
  | KnBrDraftOrderDeleteFailure;
