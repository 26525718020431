import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  IconModule,
  ItemCounterModule,
  KeyboardFocusModule,
  PromotionsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

import { KnBrAddToCartComponent } from './kn-br-add-to-cart.component';
import { KnBrSelectCartComponent } from './kn-br-select-cart/kn-br-select-cart.component';

@NgModule({
  declarations: [KnBrAddToCartComponent, KnBrSelectCartComponent],
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    CartSharedModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    FeaturesConfigModule,
    UrlModule,
    IconModule,
    ItemCounterModule,
    KeyboardFocusModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    KnBrDirectiveModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductAddToCartComponent: {
          component: KnBrAddToCartComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrAddToCartComponent],
})
export class KnBrAddToCartModule {}
