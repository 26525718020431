import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'kn-br-banner',
  templateUrl: './kn-br-banner.component.html',
  styleUrls: ['./kn-br-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrBannerComponent implements OnInit {
  componentData$: Observable<CmsBannerComponent> = this.componentData.data$;
  constructor(protected componentData: CmsComponentData<CmsBannerComponent>) {}
  ngOnInit(): void {}
}
