import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KN_BR_CONTEXT_QUOTE_FEATURE, KnBrQuoteContextState } from 'src/app/store/kn-br-quote-context/kn-br-quote-context.state';



export const getQuoteContextState = createFeatureSelector<KnBrQuoteContextState>(KN_BR_CONTEXT_QUOTE_FEATURE);

export const getQuoteContext = createSelector(getQuoteContextState, (state: KnBrQuoteContextState) =>
  state ? state.quoteContext : null
);
