<div class="modal-header col-md-12 float-left p-3">
  <h4 class="modal-title font-size-18 color-font font-text-bold">
    {{ 'downloadConfirmationDialog.heading' | cxTranslate }}
  </h4>
  <button type="button" class="close pb-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body col-md-12 float-left p-3 border-bottom">
  <label class="label-content">
    {{ 'downloadConfirmationDialog.message' | cxTranslate }}
  </label>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary col-md-3" (click)="dismiss()">
    {{ 'downloadConfirmationDialog.closeBtn' | cxTranslate }}
  </button>
</div>
