import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { GlobalMessageService, GlobalMessageType, WindowRef } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { from } from 'rxjs';
import { take, takeLast, tap, withLatestFrom } from 'rxjs/operators';
import { knBrAuthService } from 'src/app/core/kn-br-auth/kn-br-user-auth/facade/kn-br-auth.service';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { environment } from 'src/environments/environment';
import { KnBrLoginFormComponent } from 'src/feature-libs/kn-br-user/kn-br-account/components/kn-br-login-form/kn-br-login-form.component';
import { SloanPolicyReacceptanceComponent } from '../sloan-policy-reacceptance/sloan-policy-reacceptance.component';

@Component({
  selector: 'sloan-login-form',
  templateUrl: './sloan-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SloanLoginComponent extends KnBrLoginFormComponent implements OnInit, OnDestroy {
  iconTypes = ICON_TYPE;
  SiteAccessId = environment.siteAccessId;
  GroupKey = environment.groupKey;
  sloantermsofuse = environment.sloantermsofuse;
  sloanprivacypolicy = environment.sloanprivacypolicy;
  reacceptancemodalRef;
  userIdsmall: string;
  constructor(
    protected auth: knBrAuthService,
    protected globalMessageService: GlobalMessageService,
    protected fb: FormBuilder,
    protected winRef: WindowRef,
    protected loginFormComponentService: LoginFormComponentService,
    protected knBrCommonService: KnBrCommonService,
    protected modalService: NgbModal
  ) {
    super(auth, globalMessageService, fb, winRef, loginFormComponentService, knBrCommonService);
  }

  ngOnInit(): void {
    // super.ngOnInit();
    this.removeQualtrics();
  }

  removeQualtrics() {
    setTimeout(() => {
      document.getElementById('QSIFeedbackButton-btn')
        ? (document.getElementById('QSIFeedbackButton-btn').style.display = 'none')
        : null;
    }, 50);
  }

  protected loginUser(): void {
    const { userId, password } = this.form.controls;
    from(
      this.auth.loginWithCredentials(
        environment.BASE_SITE[0] + '|' + userId.value.toLowerCase(), // backend accepts lowercase emails only
        password.value
      )
    )
      .pipe(
        withLatestFrom(this.auth.isUserLoggedIn()),
        tap(([_, isLoggedIn]) => {
          if (isLoggedIn) {
            this.userIdsmall = userId.value.toLowerCase();
            this.knBrCommonService.checkPactSafeAPI(this.SiteAccessId, this.GroupKey, this.userIdsmall);
            this.knBrCommonService.checkpactsafedata$.pipe(take(2)).pipe(takeLast(1)).subscribe(res => {
              if (res[environment.termsofuse] == false || res[environment.Privacypolicy] == false) {
                let ngbModalOptions: NgbModalOptions = {
                  backdrop: 'static',
                  keyboard: false
                };
                if (!this.modalService.hasOpenModals()) {
                  this.reacceptancemodalRef = this.modalService.open(SloanPolicyReacceptanceComponent, ngbModalOptions);
                }
              }
            }
            );
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
          }
        })
      )
      .subscribe();
  }

  checkIsValidUser() {
    if (this.form.valid) {
      const { userId, password } = this.form.controls;
      this.knBrCommonService.checkIsValidUser(environment.BASE_SITE[0] + '|' + userId.value).subscribe(
        (response: any) => {
          if (response?.response === 'Authorized') {
            this.knBrCommonService.setLoggedIn(true);
            this.loginUser();
          } else {
            this.globalMessageService.add(response, GlobalMessageType.MSG_TYPE_ERROR);
          }
        },
        (error) => {
          const errorResponse = error.error;
          if (errorResponse && errorResponse.errors && errorResponse.errors.length > 0) {
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(errorResponse.errors[0].message, GlobalMessageType.MSG_TYPE_ERROR);
          }
        }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }
  ngOnDestroy() {
    setTimeout(() => {
      document.getElementById('QSIFeedbackButton-btn')
        ? (document.getElementById('QSIFeedbackButton-btn').style.display = 'block')
        : null;
    }, 50);
  }
}
