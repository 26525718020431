<!-- <cx-navigation-ui [node]="node$ | async" [ngClass]="styleClass$ | async" [flyout]="false"> </cx-navigation-ui> -->
<ng-container *ngIf="(node$ | async)?.children[0] as nodes">
  <ul id="accordion" class="accordion">
    <li *ngFor="let menu of nodes.children; let i = index" [class.active]="menu.active">
      <div *ngIf="menu.url" class="menu" (click)="toggle(menu, i)" [class.highlight-menu]="menu.isCurrentUrl">
        <cx-generic-link
          [class]="menu.children && menu.children.length > 0 ? 'd-inline-block' : 'd-block'"
          [url]="menu.url"
          [target]="menu.target"
        >
          <ng-container *ngIf="menu.media">
            <cx-media [container]="menu.media"></cx-media>
          </ng-container>
          {{ menu.title }}
        </cx-generic-link>
        <i *ngIf="menu.children && menu.children.length > 0" class="fa"></i>
      </div>
      <ul
        class="submenu"
        *ngIf="menu.children && menu.children.length > 0"
        #submenu
        [style.height.px]="menu.active ? submenu.scrollHeight : 0"
      >
        <li *ngFor="let submenu of menu.children[0].children" [class.highlight-menu]="submenu.isCurrentUrl">
          <cx-generic-link class="d-block" [url]="submenu.url" [target]="submenu.target">
            <ng-container *ngIf="submenu.media">
              <cx-media [container]="submenu.media"></cx-media>
            </ng-container>
            {{ submenu.title }}
          </cx-generic-link>
        </li>
      </ul>
    </li>
  </ul>
</ng-container>
