<div class="col-12 float-left p-0 mt-2">
  <h2 class="mb-1 color-font">{{ 'orderImport.label.pageHeader1' | cxTranslate }}</h2>
  <h5 class="text-muted font-sm-size">{{ 'orderImport.label.pageHeader2' | cxTranslate }}</h5>
  <div class="col-12 float-left p-0 mt-2 bg-light-gray">
    <div class="col-12 float-left p-4">
      <div class="col-md-6 col-xs-12 mb-3 mb-md-0 pl-0 float-left border-right">
        <h4 class="color-font">{{ 'orderImport.label.fileFormatHeader' | cxTranslate }}</h4>
        <li class="text-muted font-sm-size">{{ 'orderImport.label.format1' | cxTranslate }}</li>
        <a (click)="downloadCartImportCSV()" class="font-color-knbr-primary download-links mt-3">
          <i class="fas fa-download pr-1" aria-hidden="true"></i>
          {{ 'quoteCommon.label.downloadExcelTemplate' | cxTranslate }}
        </a>
      </div>
      <div class="col-md-6 col-xs-12 pr-0 float-left">
        <h4 class="color-font">{{ 'orderImport.label.fileUploadHeader' | cxTranslate }}</h4>
        <file-upload class="d-inline-flex" [accept]="documentTypes" simple [control]="fileUploadControl">
          <ng-template let-control="control" #button>
            <a class="pl-4 pr-4 btn btn-primary">{{ 'orderImport.label.chooseFile' | cxTranslate }}</a>
          </ng-template>
        </file-upload>
        <ng-container *ngIf="fileUploadControl.value.length > 0">
          <ng-container *ngFor="let file of fileUploadControl.value">
            <span class="ml-3 font-italic">{{ file.name }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-12 float-left p-0 pt-3 d-flex justify-content-end">
    <button type="button" class="col-lg-2 col-md-3 col-12 btn btn-primary mt-1" (click)="onSubmit()">
      {{ 'orderImport.label.import' | cxTranslate }}
    </button>
  </div>
</div>
