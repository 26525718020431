<div class="col-md-12 p-0 pl-2 float-left" *ngIf="!(isLoading$ | async)">
  <form (ngSubmit)="submitTicket()" [formGroup]="serviceTicketForm">
    <div class="alert alert-info" *ngIf="responseMessage$ | async">
      <span>{{ responseMessage$ | async }}</span>
      <button class="close" type="button" (click)="hideMsg()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="float-left col-md-12 p-0 mt-2 d-flex justify-content-center">
      <div class="float-left col-md-6 pl-0">
        <label> {{ 'serviceTicketsForm.label.subject' | cxTranslate }}* </label>
        <input class="form-control" type="text" placeholder="" formControlName="subject" />
        <cx-form-errors [control]="serviceTicketForm.get('subject')"></cx-form-errors>
      </div>
    </div>
    <div class="float-left col-md-12 p-0 mt-2 d-flex justify-content-center">
      <div class="float-left col-md-6 pl-0">
        <label> {{ 'serviceTicketsForm.label.message' | cxTranslate }}* </label>
        <textarea formControlName="message" class="form-control" placeholder="" rows="8"></textarea>
        <cx-form-errors [control]="serviceTicketForm.get('message')"></cx-form-errors>
      </div>
    </div>
    <div class="float-left col-md-12 p-0 mt-2 d-flex justify-content-center">
      <div class="float-left col-md-6 col-xs-12 pl-0">
        <label> {{ 'serviceTicketsForm.label.selectAFile' | cxTranslate }} </label>
        <file-upload class="d-inline-flex" [accept]="documentTypes" simple [control]="fileUploadControl">
          <ng-template let-control="control" #button>
            <a class="pl-4 pr-4 btn btn-primary">{{ 'serviceTicketsForm.label.chooseFile' | cxTranslate }}</a>
          </ng-template>
        </file-upload>
        <ng-container *ngIf="fileUploadControl.value.length == 0">
          <span class="ml-3 font-italic">{{ 'serviceTicketsForm.label.noFileChosen' | cxTranslate }}</span>
        </ng-container>
        <ng-container *ngIf="fileUploadControl.value.length > 0">
          <ng-container *ngFor="let file of fileUploadControl.value">
            <span class="ml-3 font-italic">{{ file.name }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="float-left col-md-12 p-0 mt-2 d-flex justify-content-center">
      <div class="float-left col-md-6 pl-0">
        <label>
          {{ 'serviceTicketsForm.label.category' | cxTranslate }}
        </label>
        <ng-select
          [items]="serviceTicketCategoryList$ | async"
          bindLabel="Text"
          bindValue="Key"
          formControlName="ticketCategory"
          [clearable]="false"
        >
        </ng-select>
      </div>
    </div>
    <div class="float-left col-md-12 p-0 mt-3 d-flex justify-content-center">
      <div class="float-left col-md-3">&nbsp;</div>
      <div class="float-left col-md-3">
        <button class="btn btn-block btn-primary" type="submit">
          {{ 'serviceTicketsForm.button.submit' | cxTranslate }}
        </button>
      </div>
    </div>
  </form>
</div>
<ng-container *ngIf="isLoading$ | async">
  <cx-spinner></cx-spinner>
</ng-container>
