import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

import { KnBrDraftOrderDeleteItemsComponent } from './kn-br-draft-order-delete-items.component';

@NgModule({
  declarations: [KnBrDraftOrderDeleteItemsComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderDeleteItemsComponent: {
          component: KnBrDraftOrderDeleteItemsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrDraftOrderDeleteItemsModule {}
