import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { GenericLinkModule, NavigationModule } from '@spartacus/storefront';
import { KnBrFooterComponent } from './kn-br-footer.component';

@NgModule({
    declarations: [KnBrFooterComponent],
    imports: [
        CommonModule,
        RouterModule,
        NavigationModule,
        GenericLinkModule,
        I18nModule,
        ConfigModule.withConfig({
            cmsComponents: {
                FooterNavigationComponent: {
                    component: KnBrFooterComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrFooterComponent]
})
export class KnBrFooterModule {}
