import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CSAgentLoginFormComponent } from '@spartacus/asm/components';

@Component({
  selector: 'cx-csagent-login-form',
  templateUrl: './kn-br-csagent-login-form.component.html',
  styleUrls: ['./kn-br-csagent-login-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KnBrCSAgentLoginFormComponent extends CSAgentLoginFormComponent implements OnInit {
  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
