import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'knbr-kn-br-download-confirmation-dialog',
  templateUrl: './kn-br-download-confirmation-dialog.component.html',
  styleUrls: ['./kn-br-download-confirmation-dialog.component.scss'],
})
export class KnBrDownloadConfirmationDialogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  dismiss() {
    this.activeModal.dismiss();
  }
}
