import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';

import { KnBrBannerComponent } from './kn-br-banner.component';

@NgModule({
    declarations: [KnBrBannerComponent],
    imports: [
        CommonModule,
        RouterModule,
        GenericLinkModule,
        MediaModule,
        ConfigModule.withConfig({
            cmsComponents: {
                SimpleResponsiveBannerComponent: {
                    component: KnBrBannerComponent,
                },
                BannerComponent: {
                    component: KnBrBannerComponent,
                },
                SimpleBannerComponent: {
                    component: KnBrBannerComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrBannerComponent]
})
export class KnBrBannerModule {}
