import { Injectable } from '@angular/core';
import { ViewModes } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductViewModeService {
  viewMode$ = new BehaviorSubject<ViewModes>(ViewModes.Grid);
  constructor() {}

  setViewMode(mode) {
    this.viewMode$.next(mode);
  }

  getViewMode$(): Observable<string> {
    return this.viewMode$.asObservable();
  }
}
