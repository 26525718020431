<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>
<section class="col-md-12 p-0 login-wrapper d-flex justify-content-center align-items-center">
  <div class="container fluid p-0">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="col-md-12 float-left knbr-login-forms mt-4 d-flex justify-content-center p-0">
        <div class="col-lg-5 col-sm-6 login-forms p-4">
          <div class="form-group mt-3">
            <i class="fas fa-user fa-lg" aria-hidden="true"></i>
            <input
              type="text"
              required
              class="form-control w-100"
              formControlName="userEmail"
              placeholder="{{ 'forgottenPassword.emailAddress.placeholder' | cxTranslate }}"
              type="email"
            />
            <span class="highlight"></span>
            <cx-form-errors [control]="form.get('userEmail')"></cx-form-errors>
          </div>

          <div class="login-btns d-flex col-md-12 mt-3 pl-0 pr-0 p-res-0">
            <button [disabled]="form.disabled" class="btn btn-lg btn-primary float-right col-md-12 m-res-0">
              {{ 'common.submit' | cxTranslate }}
            </button>
          </div>
          <div class="login-btns d-flex col-md-12 mt-3 pl-0 pr-0 p-res-0 float-right">
            <a class="btn btn-block btn-secondary" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
              'common.cancel' | cxTranslate
            }}</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
