import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KnBrCustomerContextEffects } from './kn-br-customer-context.effects';
import { knBrCustomerContextReducer } from './kn-br-customer-context.reducer';
import { KN_BR_CONTEXT_CUSTOMER_FEATURE } from './kn-br-customer-context.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_CONTEXT_CUSTOMER_FEATURE, knBrCustomerContextReducer),
    EffectsModule.forFeature([KnBrCustomerContextEffects]),
  ],
})
export class KnBrCustomerContextStateModule {}
