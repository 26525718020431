import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

import { KnBrDraftOrderItemModule } from './kn-br-draft-order-item/kn-br-draft-order-item.module';
import { KnBrDraftOrderItemsComponent } from './kn-br-draft-order-items.component';
import { KnBrQuoteReferenceModule } from './kn-br-quote-reference/kn-br-quote-reference.module';

@NgModule({
  declarations: [KnBrDraftOrderItemsComponent],
  imports: [
    CommonModule,
    KnBrDraftOrderItemModule,
    I18nModule,
    KnBrQuoteReferenceModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderItemsComponent: {
          component: KnBrDraftOrderItemsComponent,
        },
        KnBrDraftOrderItemsComponentReadOnly: {
          component: KnBrDraftOrderItemsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrDraftOrderItemsModule {}
