<div #dialog>
  <!-- Modal Header -->
  <ng-container>
    <div class="cx-dialog-header modal-header d-flex align-items-center p-2 pl-3 pr-3">
      <div class="cx-dialog-title modal-title font-size-18 font-text-bold">
        {{ 'quoteCommon.label.quote' | cxTranslate }}
      </div>
      <button type="button" class="close" aria-label="Close" (click)="dismissModal('Cross click')">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE" class="font-xl-size"></cx-icon>
        </span>
      </button>
    </div>
    <!-- Modal Body -->
    <div class="cx-dialog-body modal-body p-3 float-left w-100">
      <div class="cx-dialog-row">
        <div class="cx-dialog-item col-sm-12 col-md-12">
          <div class="form-group mt-3 col-sm-12 col-md-6 pl-0 float-left mb-3 p-res-0 p-0">
            <ng-select
              [items]="quoteList"
              bindLabel="code"
              bindValue="code"
              [(ngModel)]="quoteCode"
              [placeholder]="'quoteCommon.label.quoteOrderNumber' | cxTranslate"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div>{{ item.code | removeLeadingZeros }} - {{ item.jobName }}</div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div>{{ item.code | removeLeadingZeros }} - {{ item.jobName }}</div>
              </ng-template></ng-select
            >
          </div>
          <div class="col-sm-12 col-md-6 float-right p-0 d-flex justify-content-start ali align-items-center">
            <a class="btn color-knbr-primary m-3 text-white" (click)="saveQuote()" [class.disabled]="!quoteCode">{{
              'button.save' | cxTranslate
            }}</a>
            <a class="btn color-knbr-primary text-white" (click)="goToCreateNewQuote()">{{
              'button.createNewQuote' | cxTranslate
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
