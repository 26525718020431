import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, PageComponentModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';

import { KnBrDraftOrderSearchComponent } from './kn-br-draft-order-search.component';

@NgModule({
  declarations: [KnBrDraftOrderSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18nModule,
    FormErrorsModule,
    NgSelectModule,
    NgbTooltipModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderSearchComponent: {
          component: KnBrDraftOrderSearchComponent,
        },
      },
    } as CmsConfig),
  ],

  exports: [KnBrDraftOrderSearchComponent],
})
export class KnBrDraftSearchModule {}
