import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';

import { KnBrQuoteRejectCommentsComponent } from './kn-br-quote-reject-comments.component';

@NgModule({
  declarations: [KnBrQuoteRejectCommentsComponent],
  imports: [CommonModule, FormsModule, I18nModule],
})
export class KnBrQuoteRejectCommentsModule {}
