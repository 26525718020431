<div class="modal-header col-md-12 float-left p-3">
  <h4 class="modal-title font-size-18 color-font font-text-bold">
    {{ 'uploadNewTemplateDialog.heading' | cxTranslate }}
  </h4>
  <button type="button" class="close pb-0" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body col-md-12 float-left p-3 border-bottom">
  <form [formGroup]="uploadTemplateForm" (ngSubmit)="upload()">
    <div class="col-md-12 p-0 float-left">
      <div class="float-left col-md-12 p-0 d-flex align-items-center">
        <div class="float-left col-md-4 pl-0">
          <label>
            <span class="label-content required"
              >{{ 'uploadNewTemplateDialog.labels.templateName' | cxTranslate }}*</span
            >
          </label>
        </div>
        <div class="float-left col-md-8 p-0">
          <input class="form-control" type="text" placeholder="" formControlName="templateName" />
          <cx-form-errors [control]="uploadTemplateForm.get('templateName')"></cx-form-errors>
        </div>
      </div>
      <div class="float-left col-md-12 p-0 mt-3 d-flex align-items-center">
        <div class="float-left col-md-4 pl-0">
          <label>
            <span class="label-content required">{{ 'uploadNewTemplateDialog.labels.csvFile' | cxTranslate }}*</span>
          </label>
        </div>
        <div class="float-left col-md-8 p-0 uploadTemplate">
          <file-upload class="d-inline-flex" [accept]="documentTypes" simple [control]="fileUploadControl">
            <ng-template let-control="control" #button>
              <a class="btn btn-secondary">{{ 'uploadNewTemplateDialog.labels.chooseFile' | cxTranslate }}</a>
            </ng-template>
          </file-upload>
          <ng-container *ngIf="fileUploadControl.value.length == 0">
            <span class="ml-3 font-italic">{{ 'uploadNewTemplateDialog.labels.noFileChosen' | cxTranslate }}</span>
          </ng-container>
          <ng-container *ngIf="fileUploadControl.value.length > 0">
            <ng-container *ngFor="let file of fileUploadControl.value">
              <span class="ml-3 font-italic">{{ file.name }}</span>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary col-md-3" (click)="upload()">
    {{ 'uploadNewTemplateDialog.buttons.yes' | cxTranslate }}
  </button>
  <button type="button" class="btn btn-secondary col-md-3" (click)="dismiss()">
    {{ 'uploadNewTemplateDialog.buttons.no' | cxTranslate }}
  </button>
</div>
