import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UrlModule, I18nModule, ConfigModule, CmsConfig } from '@spartacus/core';
import { KnBrOrderDetailsOverviewComponent } from './kn-br-order-details-overview.component';
import { PageComponentModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  declarations: [KnBrOrderDetailsOverviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    NgbTooltipModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountOrderDetailsOverviewComponent: {
          component: KnBrOrderDetailsOverviewComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrOrderDetailsOverviewModule {}
