import { NgModule } from '@angular/core';
import { OrderByPipe } from '../order-by.pipe';
import { SafePipe } from '../safe.pipe';
import { KnBrRemoveBaseSiteIdPipe } from './kn-br-remove-base-site-id.pipe';
import { RemoveLeadingZerosPipe } from './remove-leading-zeros.pipe';

@NgModule({
  declarations: [RemoveLeadingZerosPipe, SafePipe, OrderByPipe, KnBrRemoveBaseSiteIdPipe],
  exports: [RemoveLeadingZerosPipe, SafePipe, OrderByPipe, KnBrRemoveBaseSiteIdPipe],
})
export class KnBrCustomPipesModule {}
