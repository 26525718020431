import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';

import { KnBrDraftOrderCommentsPopupComponent } from './kn-br-draft-order-comments-popup.component';

@NgModule({
  declarations: [KnBrDraftOrderCommentsPopupComponent],
  imports: [CommonModule, FormsModule, I18nModule],
})
export class KnBrDraftOrderCommentsPopupModule {}
