<ng-container *ngIf="!(isLoading$ | async)">
  <div
    class="col-lg-12 customer-search bg-white p-0 float-left mb-3 border-bottom"
    *ngIf="customerResult$ | async as customerResult"
  >
    <div
      class="customersearch-list-table mt-3 float-left col-lg-12 p-0"
      *ngIf="customerResult && customerResult.length"
    >
      <div
        class="
          customersearch-list-titles
          border-top border-bottom
          float-left
          w-100
          pl-2
          pr-2
          pt-3
          pb-3
          d-none d-sm-none d-lg-block
        "
      >
        <div class="col-md-3 padding-res-0 float-left font-text-600">
          {{ 'customer.label.name' | cxTranslate }}
        </div>
        <div class="col-md-2 float-left font-text-600">{{ 'customer.label.number' | cxTranslate }}</div>
        <div class="col-md-2 float-left font-text-600">{{ 'customer.label.street' | cxTranslate }}</div>
        <div class="col-md-2 float-left font-text-600">{{ 'customer.label.city' | cxTranslate }}</div>
        <div class="col-md-1 float-left font-text-600">{{ 'customer.label.state' | cxTranslate }}</div>
        <!-- <div class="col-md-1 float-left font-text-600">{{ 'customer.label.zipCode' | cxTranslate }}</div> -->
        <div class="col-md-2 float-left font-text-600">{{ 'customer.label.phoneNo' | cxTranslate }}</div>
      </div>

      <div
        class="
          bg-white
          customersearch-list-titles
          table-content
          border-bottom
          float-left
          w-100
          p-2
          font-weight-normal
          d-flex
          align-items-center
        "
        *ngFor="let customer of customerResult"
        (click)="storeInContext(customer)"
      >
        <div class="col-md-3 title-wrap padding-res-0 float-left">
          <span class="res-titles">{{ 'customer.label.name' | cxTranslate }}:</span> {{ customer.customerName }}
        </div>
        <div class="col-md-2 float-left title-wrap">
          <span class="res-titles">{{ 'customer.label.number' | cxTranslate }}:</span
          >{{ customer.customerNumber | removeLeadingZeros }}
        </div>
        <div class="col-md-2 float-left title-wrap">
          <span class="res-titles">{{ 'customer.label.street' | cxTranslate }}:</span>{{ customer.street }}
        </div>
        <div class="col-md-2 float-left title-wrap">
          <span class="res-titles">{{ 'customer.label.city' | cxTranslate }}:</span> {{ customer.city }}
        </div>
        <div class="col-md-1 float-left title-wrap">
          <span class="res-titles">{{ 'customer.label.state' | cxTranslate }}:</span> {{ customer.state }}
        </div>
        <!-- <div class="col-md-1 float-left title-wrap">
          <span class="res-titles">{{ 'customer.label.zipCode' | cxTranslate }}:</span> {{ customer.postalCode }}
        </div> -->
        <div class="col-md-2 float-left title-wrap">
          <span class="res-titles">{{ 'customer.label.phoneNo' | cxTranslate }}:</span> {{ customer.telephone }}
        </div>
      </div>
    </div>
    <div class="alert-info p-3 rounded mt-3" *ngIf="customerResult && customerResult.length < 1">
      {{ 'customer.label.noCustomerFound' | cxTranslate }}
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isLoading$ | async">
  <cx-spinner></cx-spinner>
</ng-container>
