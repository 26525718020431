import { createFeatureSelector, createSelector } from '@ngrx/store';

import { KN_BR_SEARCH_CUSTOMER_FEATURE, KnBrCustomerState } from './kn-br-customer.state';

export const getCustomerState = createFeatureSelector<KnBrCustomerState>(KN_BR_SEARCH_CUSTOMER_FEATURE);

export const getCustomers = createSelector(getCustomerState, (state: KnBrCustomerState) => state.customers);

export const getCustomerLoader = createSelector(getCustomerState, (state: KnBrCustomerState) => state.isLoading);

export const getSearchDisabled = createSelector(getCustomerState, (state: KnBrCustomerState) => state.searchDisabled);
