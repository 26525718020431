import { Component, OnInit } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'knbr-order-tools',
  templateUrl: './kn-br-order-tools.component.html',
})
export class KnBrOrderToolsComponent implements OnInit {
  componentData$: Observable<CmsNavigationComponent> = this.componentData.data$;
  node$: Observable<any> = this.service.createNavigation(this.componentData.data$);
  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService
  ) {}

  ngOnInit() {}
}
