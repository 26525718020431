/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { Injectable, inject } from '@angular/core';
import {
  GlobalMessageService,
  RoutingService,
} from '@spartacus/core';
import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '../../../kn-br-profile/root/user-password.facade';
@Injectable()
export class KnbrResetPasswordComponentService extends ResetPasswordComponentService {
  // TODO: (CXSPA-7315) Remove feature toggle in the next major
  constructor(
    protected userPasswordService: UserPasswordFacade,
    protected routingService: RoutingService,
    protected globalMessage: GlobalMessageService
  ) {
    super(userPasswordService, routingService, globalMessage);
  }
}
